import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '@shared/services/utility.service';

@Component({
  selector: 'oes-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.scss'],
})
export class StatusLabelComponent implements OnInit {
  @Input() statusColor: string;
  @Input() status: string;

  textColor: string;

  constructor(private _utilityService: UtilityService) {};

  ngOnInit() {
    if (this.statusColor) {
      this.textColor = this._utilityService.findTextColor(this.statusColor);
    }
  }
}
