<div class="home" data-testid="tdbgroup-home-container">
  <div class="header-images">
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/tdb-bg.jpg')"></div>
  </div>

  <div class="tdbgroup-content-top">
    <img src="/assets/images/tdb/tdb-logo.png" width="200px" />
    <p>The Trade and Development Bank (TDB) has established the Regional Energy Access Finance Platform (REAF) supported by the World Bank to grow distributed renewable energy (DRE) and clean cooking private sector initiatives in TDB member states.</p>
  </div>

  <div class="tdbgroup-content-bottom">
    <div class="tdbgroup-program">
      <p><b>Lending to Distributed Renewable Energy (DRE) and clean cooking companies</b></p>
      <p>Debt financing to DRE and clean cooking companies to expand electricity and clean cooking access in TDB members states in the Eastern and Southern Africa Region.</p>
      <div class="tdbgroup-program-footer">
        <button [routerLink]="['../finance/programs']" class="btn btn-primary" disabled>{{ 'buttons.coming-soon' | translate }}</button>
      </div>
    </div>

    <div class="tdbgroup-program">
      <p><b>Performance Based Grant Facility for the frontier markets</b></p>
      <p>Grant funding via Results-Based Financing (RBF) and performance-based catalytic grants to support Distributed Renewable Energy (DRE) and clean cooking expansion in markets unserved or underserved by national programs.</p>
      <div class="tdbgroup-program-footer">
        <button [routerLink]="['../finance/programs']" class="btn btn-primary">{{ 'buttons.apply' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="tdbgroup-institutions">
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/tdb-inst.jpg')"></div>
  </div>
</div>
