import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DynamicFormService } from '@shared/components/dynamic-form/dynamic-form.service';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';

import { ExternalTaskApiConstants } from './external-task.constant';
import { OesTask } from './task.model';

@Injectable({
  providedIn: 'root'
})
export class ExternalTaskService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _dynamicFormService: DynamicFormService) {
    super(_restApiWrapperService);
  }

  getExternalTask(taskId: string): Observable<OesTask> {
    return this.get<OesTask>(ExternalTaskApiConstants.externalTask.detail(taskId), this.getOptions());
  }

  getExternalTaskMilestoneForm(taskId: string, milestoneId: string): Observable<any> {
    return this.get<any>(ExternalTaskApiConstants.milestone.getForm(taskId, milestoneId), this.getOptions());
  }

  saveExternalTaskMilestoneFormDate(taskId: string, milestoneId: string, json: {}): Observable<any> {
    let jsonObject = this._dynamicFormService.removeBlankNodes({ ...json });
    // add Validate button before Submit button
    jsonObject = this._dynamicFormService.checkValidateButton(jsonObject);
    // add save button to all forms
    jsonObject = this._dynamicFormService.checkSaveButton(jsonObject);
    const file = new File([JSON.stringify(jsonObject)], 'milestone-form', { type: 'text/json' });
    const formData = new FormData();
    formData.append('file', file);
    return this.post<any>(ExternalTaskApiConstants.milestone.saveForm(taskId, milestoneId), formData, this.getOptions());
  }

  submitExternalTaskForm(taskId: string, milestoneId: string): Observable<OesTask> {
    return this.get<OesTask>(ExternalTaskApiConstants.milestone.submitForm(taskId, milestoneId), this.getOptions());
  }

  private getOptions() {
    const externalToken = this.getExternalToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${ externalToken }`);
    const options = { headers };
    return options;
  }

  private getExternalToken(): string {
    const match = document.cookie.match(new RegExp('(^| )externalToken=([^;]+)'));
    return match ? decodeURIComponent(match[2]) : '';
  }
}
