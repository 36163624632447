import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from './error.service';
import { LoggingService } from './logging.service';
import { EventService } from '@shared/event.service';

/**
 * Custom Error Handler Service. The definition is in app.module.
 */
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  public handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(EventService);

    let message = '';
    let stackTrace: any;

    if (error?.message?.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
      return;
    }

    if (errorService) {
      if (error instanceof HttpErrorResponse) {
        // server error
        message = errorService.getServerMessage(error);
        stackTrace = errorService.getServerStack(error);
        const userMsg = (error.error && error.error.message) || 'A server error occurred.';
        // Popup an error message.
        notifier.error(userMsg);
      } else {
        // client error
        // Don't show any javascript errors to a user but output it to the console.
        message = errorService.getClientMessage(error);
        stackTrace = errorService.getClientStack(error);
      }
    }

    // log actual error message. Inspectlet will record.
    logger.logError(message, stackTrace);
  }
}
