import { environment } from '@environments/environment';

export class TaskApiConstants {
  public static tasks = environment.serverUri + `api/${environment.apiVersion}/tasks`;

  public static task = {
    create: () => {
      return `${TaskApiConstants.tasks}`;
    },
    detail: (taskId: string) => {
      return `${TaskApiConstants.tasks}/${taskId}`;
    },
    find: () => {
      return `${TaskApiConstants.tasks}`;
    }
  };
}
