import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-tdbgroup',
  templateUrl: './tdbgroup.component.html',
  styleUrls: ['./tdbgroup.component.scss']
})
export class TdbGroupComponent {

  constructor() { }

}
