import { ROLE_TYPE } from '@user/role-type';
import { NavItem } from '../nav-item.model';
import { MainMenuCategory } from '@main-navigation/shared/main-menu-category.enum';

export class CommercialInvestorDemoMonitorNav {
  public static item: NavItem = {
    title: 'main-navigation.monitor',
    icon: '<img src="./assets/icons/line-icons/nav/colors/pie-chart-03.svg" width="24px" height="24px">',
    themedIcon: {
      avorenewables: '<img src="./assets/images/avorenewables/icons/pie-chart-03.svg" width="24px" height="24px">'
    },
    bold: true,
    type: MainMenuCategory.operate,
    roles: [ROLE_TYPE.DATA_ANALYST],
    children: [
      {
        title: 'main-navigation.fernview',
        externalUrl: 'https://fernview.odysseyenergysolutions.com/',
        testId: 'reporting-nav-link',
        roles: [ROLE_TYPE.ORGANIZATION_ADMIN],
      },
      {
        title: 'main-navigation.dashboard',
        url: '/oes/analytics/dashboards',
        testId: 'analytics-nav-link',
        roles: [ROLE_TYPE.DATA_ANALYST],
      },
      {
        title: 'main-navigation.crm',
        url: '/oes/crm/overview',
        testId: 'crm-nav-link',
        roles: [ROLE_TYPE.COMMERCIAL_INVESTOR_DEMO_USER],
      }
    ]
  };
}
