import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { UserService } from '@user/user.service';
import { take } from 'rxjs/operators';
import { User } from '@user/user.model';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { Project } from '@project/shared/project.model';
import { Milestone } from '../milestone.model';
import { MilestoneStatus } from '../milestone-status.model';
import { MILESTONE_CONTENT_TYPE, MILESTONE_TYPE } from '../milestone-type.enum';
import { Document } from '@shared/components/files/shared/models/document.model';
import { MilestoneService } from '../milestone.service';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import FileSaver from 'file-saver';
import { DateTime } from 'luxon';
import { DOC_TYPE } from '@shared/components/files/shared/models/milestone-status.enum';
import { TaskService } from 'src/app/task/task.service';
import { OesTaskType } from 'src/app/task/task-type.enum';
import { OesTask } from 'src/app/task/task.model';
import { Subject, Subscription } from 'rxjs';
import { Installer } from '@project/installers/installer.model';
import { Offtaker } from '@project/offtakers/offtaker.model';

@Component({
  selector: 'oes-milestone-view',
  templateUrl: './milestone-view.component.html',
  styleUrls: ['./milestone-view.component.scss'],
})
export class MilestoneViewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('deleteAttachmentWarningModal', {static: false}) deleteAttachmentWarningModal: ModalDialogComponent;
  @ViewChild('tabContainer', {static: false}) tabContainer: ElementRef;
  @ViewChildren('tabElement') tabElements!: QueryList<ElementRef>;

  @Input() index: number;
  @Input() initialStatusSelection: any;
  @Input() isProgramOwner: boolean;
  @Input() milestone: Milestone;
  @Input() milestoneForm: any;
  @Input() milestoneStatus: MilestoneStatus;
  @Input() milestoneType: MILESTONE_TYPE;
  @Input() project: Project;
  @Input() projectOfftaker: Offtaker;
  @Input() projectInstaller: Installer;
  @Input() readOnly: boolean;
  @Input() statusDropdownOptions: any[];
  @Input() statusChanged!: (event: any) => void;

  @Output() closeMilestoneView: EventEmitter<void> = new EventEmitter<void>();

  allNoteDocuments: {[key: string]: Document[]};
  currentTask: OesTask;
  documentsToDisplay: Document[];
  formValid: boolean = false;
  isLoading = false;
  isProjectOwner = false;
  milestoneContentTypes = MILESTONE_CONTENT_TYPE;
  milestoneTypes = MILESTONE_TYPE;
  tabs = {
    form: true,
    notes: false,
    attachments: false,
  };
  timeUpdated: Date;
  updateUserEmail: string;
  user: User;

  private attachmentIndexToDelete: number;
  private refetchTaskSubscription: Subscription;

  constructor(private _documentDataService: DocumentDataService,
              private _taskService: TaskService,
              private _milestoneService: MilestoneService,
              private _userService: UserService) {
  };

  ngOnInit() {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
      });
    const projectPermissions = this.project?.projectPermissions;
    this.readOnly = projectPermissions?.project?.modulePermissions?.milestones?.readOnly;
    this.isProgramOwner = projectPermissions?.currentUser?.programOwner;
    this.isProjectOwner = projectPermissions?.currentUser?.projectOwner;
    this.documentsToDisplay = this.milestone.documents.filter(doc =>
      (doc.documentKey.docType as unknown) === 'FILE'
    );
    this.initTabSelect();
    this.findTasks();
    this.refetchTaskSubscription = this._milestoneService.refetchTask$.subscribe(() => {
      this.findTasks();
    });
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.milestone?.currentValue?.documents) {
      this.isLoading = false;
      this.documentsToDisplay = this.milestone.documents.filter(doc =>
        (doc.documentKey.docType as unknown) === 'FILE'
      );
    }
    if (changes.milestone?.currentValue?.id !== changes.milestone?.previousValue?.id) {
      this.initTabSelect();
    }
  }

  ngAfterViewInit(): void {
    this.setTabsContainerWidth();
  }

  ngOnDestroy() {
    this.refetchTaskSubscription.unsubscribe();
  }

  findTasks() {
    if (this.milestone?.milestoneConfig?.contentType === 'FORM') {
      this._taskService.findByTypeAndIndex(OesTaskType.MILESTONE, this.milestone.id)
        .pipe(take(1))
        .subscribe((tasks: OesTask[]) => {
          const orderedTasks = tasks.sort((a, b) => {
            if (a.expirationDateTime > b.expirationDateTime) {
              return -1;
            } else if (a.expirationDateTime <= b.expirationDateTime) {
              return 1;
            }
          });
          this.currentTask = orderedTasks.length ? orderedTasks[0] : null;
        });
    }
  }

  initTabSelect() {
    if (this.milestone?.milestoneConfig?.contentType === 'FORM') {
      this.tabs = {form: true, notes: false, attachments: false};
    } else {
      this.tabs = {form: false, notes: true, attachments: false};
    }
  }

  handleOpenMilestoneConfigEdit() {
    this._milestoneService.openMilestoneConfig({
      action: 'edit',
      milestoneInput: this.milestone,
      milestoneType: this.milestoneType,
      form: this.milestoneForm
    });
  }

  setTabsContainerWidth(): void {
    let maxWidth = 0;

    this.tabElements.forEach((tabEl: ElementRef) => {
      const tabWidth = tabEl.nativeElement.offsetWidth;
      if (tabWidth > maxWidth) {
        maxWidth = tabWidth;
      }
    });

    this.tabContainer.nativeElement.style.width = `${maxWidth}px`;
  }

  downloadDocumentsClick(documents: Document[]) {
    this.isLoading = true;
    const docKeys = documents.map(doc => doc.documentKey.key);
    const fileNames = [];
    const uniqueKeys = [];
    docKeys.forEach(key => {
      const fileNameKey = key.split('MILESTONE')[1];
      if (!fileNames.includes(fileNameKey)) {
        uniqueKeys.push(key);
        fileNames.push(fileNameKey);
      }
    });
    this._documentDataService.downloadDocuments(uniqueKeys, this.project.organization.id)
    .pipe(take(1))
    .subscribe((data: any) => {
      if (data) {
        let name = '';
        if (documents && documents.length === 1) {
          name = documents[0].path;
        } else {
          name = 'Documents_' + DateTime.local().toSQLDate() + '.zip';
        }
        FileSaver.saveAs(data, name);
        this.isLoading = false;
      }
    });
  }

  uploadDocumentClick(event: MouseEvent) {
    event.preventDefault();
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      this._milestoneService.updateFilesAdded(
        this.milestone,
        { files: Array.from(input.files) }
      );
      this.isLoading = true;
    };
    input.click();
  }

  handleOpenAttachmentDeleteWarning(event, attachmentIndexToDelete: number) {
    event.preventDefault();
    event.stopPropagation();
    this.deleteAttachmentWarningModal.show();
    this.attachmentIndexToDelete = attachmentIndexToDelete;
  }

  handleDeleteAttachment() {
    const updatedMilestone = JSON.parse(JSON.stringify(this.milestone));
    updatedMilestone.documents.splice(this.attachmentIndexToDelete, 1);
    this._milestoneService.updateMilestone(updatedMilestone);
    this.handleCloseAttachmentDeleteWarning();
  }

  handleShareMilestone() {
    this._milestoneService.openMilestoneShare(this.milestone);
  }

  handleCloseAttachmentDeleteWarning() {
    this.attachmentIndexToDelete = null;
    this.deleteAttachmentWarningModal.hide();
  }

  close() {
    this.closeMilestoneView.emit();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
