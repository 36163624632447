import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { MILESTONE_STATUS, MILESTONE_STATUS_LABEL } from '@program/shared/milestone-status.enum';
import { CsvExporterService } from '@shared/services/csv-exporter.service';
import { TranslateService } from '@ngx-translate/core';
import { Project } from '@project/shared/project.model';
import { Milestone } from '../milestone.model';
import { MilestoneStatus } from '../milestone-status.model';
import { MILESTONE_TYPE } from '../milestone-type.enum';
import { User } from '@user/user.model';
import { MilestoneService } from '../milestone.service';
import { Installer } from '@project/installers/installer.model';
import { Offtaker } from '@project/offtakers/offtaker.model';

@Component({
  selector: 'oes-milestone-list',
  templateUrl: './milestone-list.component.html',
  styleUrls: ['./milestone-list.component.scss'],
})
export class MilestoneListComponent implements OnChanges {
  @Input() addMilestone: boolean;
  @Input() emptyMessage: string = '';
  @Input() emptyListContent: TemplateRef<any>;
  @Input() isProgramOwner: boolean;
  @Input() milestoneType: MILESTONE_TYPE[];
  @Input() isLoading: boolean;
  @Input() milestones: Milestone[] = [];
  @Input() project: Project;
  @Input() projectInstaller: Installer;
  @Input() projectOfftaker: Offtaker;
  @Input() readOnly: boolean;
  @Input() showCount: boolean = true;
  @Input() showExport: boolean = true;
  @Input() title: string = '';

  milestonesRange: any[];
  completedMilestones: number;
  ititialStatusDropdownSelection: any;
  isDataLoaded: boolean = false;
  milestoneStatuses: MilestoneStatus[];
  milestoneTypes = MILESTONE_TYPE;
  openCollapsible: number = -1;
  statusDropdownOptions = Object.keys(MILESTONE_STATUS).reduce((accum, status, index) => {
    accum.push({
      id: index,
      name: this._translateService.instant(MILESTONE_STATUS_LABEL[status]),
      value: status
    });
    return accum;
  }, []);
  totalMilestones: number;
  user: User;

  constructor(private _csvExporterService: CsvExporterService,
              private _milestoneService: MilestoneService,
              private _translateService: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.milestones && changes.milestones.currentValue) {
      this.totalMilestones = this.milestones.length;
      this.completedMilestones = this.milestones.filter(milestone => milestone.status === MILESTONE_STATUS.COMPLETED).length;
      this.milestonesRange = new Array(this.totalMilestones);
    }
    if (changes.isLoading?.currentValue
      && this.isLoading === false) {
      setTimeout(() => {
        this.isDataLoaded = true;
      }, 500);
    }
  }

  handleOpenMilestoneConfigCreate() {
    this._milestoneService.openMilestoneConfig({
      action: 'create',
      milestoneType: this.milestoneType,
    });
  }

  trackByMilestone(index: number, milestone: any): string {
    return milestone.id;
  }


  // Might be reusable for milestone groups, keeping for now
  // toggleCollapsible = (index: number, component: any) => {
  //   if (this.openCollapsible === index) {
  //     this.openCollapsible = -1;
  //   } else {
  //     this.openCollapsible = index;
  //   }
  //   this.ensureVisible(component.details.nativeElement);
  // };

  // Might be reusable for milestone groups, keeping for now
  // ensureVisible(element: Element) {
  //   setTimeout(() => {
  //     const elementTop = element.getBoundingClientRect().top;
  //     const yOffset = elementTop - window.scrollY;
  //     if (yOffset < this.itemInvisibleThreshold) {
  //       const scrollAmount = elementTop - this.pageHeaderHeight;
  //       window.scrollTo({
  //         top: window.scrollY + scrollAmount,
  //         behavior: 'smooth'
  //       });
  //     }
  //   }, 500);
  // }

  exportListClick() {
    const csvInput = this.milestones.map(milestone => ({
      [this._translateService.instant('milestones.reporting.columns.name')]: milestone.name,
      [this._translateService.instant('milestones.reporting.columns.description')]: (milestone.description || '')
        .trim()
        .replace(/(\r\n|\n|\r)/gm, ' '), // Remove new lines
      [this._translateService.instant('milestones.reporting.columns.status')]: milestone.status,
      [this._translateService.instant('milestones.reporting.columns.notes')]: milestone.notes
        .map(note => `${note.organization.name}: ${note.content.trim().replace(/(\r\n|\n|\r)/gm, ' ')}`)
        .join('\n'),
      [this._translateService.instant('milestones.reporting.columns.documentation')]: milestone.documents
        .map(doc => doc.path)
        .map(path => path.split('/')[path.split('/').length - 1])
        .join('; ')
    }));
    this._csvExporterService.exportCsv(csvInput, 'Milestone Reporting');
  }
}
