import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PageStatus } from './page-status';

@Component({
  selector: 'oes-ag-grid-paginator',
  templateUrl: './ag-grid-paginator.component.html',
  styleUrls: ['./ag-grid-paginator.component.scss']
})
export class AgGridPaginatorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() maxSize = 10;  // button number. more than 10, it would be ...
  @Input() showTotalItems = true;
  @Input() totalItems: number;
  @Output() currentPage: EventEmitter<PageStatus> = new EventEmitter<PageStatus>();

  beginItemNumber = 1;
  endItemNumber: number;
  formGroup: UntypedFormGroup;
  pageSizeOptions = [
    {name: '25', value: 25},
    {name: '50', value: 50},
    {name: '100', value: 100},
    {name: '500', value: 500},
    {name: '1000 (slow)', value: 1000}
  ];

  private ngUnsubscribe: Subject<any> = new Subject();
  private pageStatus: PageStatus = {
    page: 0,
    pageSize: 100
  };

  constructor() {}

  ngOnInit() {
    this.endItemNumber = this.pageStatus.pageSize > this.totalItems ? this.totalItems : this.pageStatus.pageSize;
    this.generateForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalItems && changes.totalItems.currentValue >= 0) {
      this.updatePageRange();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private generateForm() {
    this.formGroup = new UntypedFormGroup({
      pageSizeControl: new UntypedFormControl(this.pageStatus.pageSize)
    });
    this.formGroup.controls['pageSizeControl'].valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(update => {
      this.pageStatus.pageSize = update;
      this.pageStatus.page = 0;  // set the first page
      this.updatePageRange();
      this.currentPage.emit(this.pageStatus);
    });
  }

  public pageChanged(event) {
    this.pageStatus.page = event.page - 1;
    this.updatePageRange();
    this.currentPage.emit(this.pageStatus);
  }

  private updatePageRange() {
    this.beginItemNumber = this.pageStatus.page * this.pageStatus.pageSize + 1;
    const end = (this.pageStatus.page + 1) * this.pageStatus.pageSize;
    this.endItemNumber = end < this.totalItems ? end : this.totalItems;
  }
}
