import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-amadermali',
  templateUrl: './amadermali.component.html',
  styleUrls: ['./amadermali.component.scss']
})
export class AmadermaliComponent {

  constructor() { }

}
