<div class="home" data-testid="proenergia-home-container">
  <div class="header-images">
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/bc-1.jpg')"></div>
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/bc-2.jpg')"></div>
    <div style="background-image: url('https://oes-hosted-images-public.s3.amazonaws.com/bc-3.jpg')"></div>
  </div>

  <div class="proenergia-content-top">
    <div>
      <h1>Off-Grid Energy Grant Facility</h1>
      <p>Supporting the expansion of the off-grid solar and clean cooking sectors in Mozambique with a focus on remote, underserved and vulnerable provinces with high poverty incidence.</p>
      <button [routerLink]="['../finance/programs']" class="btn btn-primary">{{ 'buttons.apply-now' | translate }}</button>
    </div>
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/energia-logo-full.png" width="275px" />
  </div>

  <div class="proenergia-logo-container">
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/world-bank-logo.svg" height="298px" />
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/funae-logo.png" width="100px" />
    <img src="https://oes-hosted-images-public.s3.amazonaws.com/bc-logo.png" width="370px" style="margin-left: 44px;" />
  </div>
</div>
