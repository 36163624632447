<oes-modal-dialog
  #milestoneConfigModal
  id="milestoneConfigModal"
  [size]="'small'"
  [allowCancel]="true"
  [allowSubmit]="true"
  [hideCloseBtn]="true"
  (submit)="onSubmit()"
  [submitButtonText]="action === 'create' ? ('milestones.add-milestone' | translate) : ('buttons.confirm' | translate)"
  [modalStyle]="'fresh'"
  (close)="milestoneConfigModal.hide()">
  <ng-container *ngIf="milestoneConfigModalShowing">
    <div class="milestone-config">
      <div class="milestone-config__header">
        <h3>{{ action === 'create' ? ('milestones.add-milestone' | translate) : ('milestones.edit-definition' | translate) }}</h3>
        <button
          class="btn btn-fresh btn-link"
          *ngIf="action === 'edit' && !milestoneInput.milestoneConfig.program"
          (click)="handleOpenDeleteWarning()"
        >
          <img src="assets/icons/line-icons/shared/red/trash.svg" role="presentation" />
          {{ 'milestones.delete-milestone' | translate }}
        </button>
      </div>
      <form #configForm="ngForm">
        <div class="section milestone-config__name">
          <h5>{{ 'milestones.name' | translate }}</h5>
          <input
            [placeholder]="'milestones.enter-name' | translate"
            type="text"
            name="name"
            class="input-control text-fresh"
            [(ngModel)]="milestoneConfig.name"
            maxlength="100"
            required
          >
          <p
            class="form-control validation-error"
            *ngIf="configForm.controls['name']?.errors?.required && (configForm.controls['name'].touched || submitAttempted)"
            >
            {{ 'milestones.validation.name-required' | translate }}
          </p>
          <p
            class="form-control validation-error"
            *ngIf="configForm.controls['name']?.errors?.maxlength && configForm.controls['name'].touched"
            >
            {{ 'milestones.validation.name-max-length' | translate }}
          </p>
        </div>
        <div class="section milestone-config__description">
          <h5>{{ 'milestones.description' | translate }}</h5>
          <textarea
            [placeholder]="'milestones.enter-description' | translate"
            name="description"
            class="input-control textarea-fresh"
            [(ngModel)]="milestoneConfig.description"
            [required]="action === 'create'"
            maxlength="1000"
          ></textarea>
          <p
            class="form-control validation-error"
            *ngIf="configForm.controls['description']?.errors?.required && (configForm.controls['description'].touched || submitAttempted)"
            >
            {{ 'milestones.validation.description-required' | translate }}
          </p>
          <p
            class="form-control validation-error"
            *ngIf="configForm.controls['description']?.errors?.maxlength && configForm.controls['description'].touched"
            >
            {{ 'milestones.validation.description-max-length' | translate }}
          </p>
        </div>
        <ng-container *ngIf="action !== 'edit' || !milestoneInput.milestoneConfig.program">
          <div class="section milestone-config__form">
            <h5>{{ 'milestones.attach-form' | translate }}</h5>
            <ng-container *ngIf="milestoneConfig?.form; else formNotUploaded">
              <div class="box-container">
                <div class="icon-round-bg success">
                  <img style="width: 20px; height: 20px;" src="assets/icons/line-icons/shared/green/check.svg" role="presentation">
                </div>
                <p>{{ milestoneConfig.form?.name || formName }}</p>
                <span>
                  <a class="box-container__link" (click)="uploadFormClick($event)" href="#">
                    {{ 'milestones.change-form' | translate }}
                  </a>
                  &nbsp;|&nbsp;
                  <a class="box-container__link danger" (click)="removeFormClick($event)" href="#">
                    {{ 'milestones.remove-form' | translate }}
                  </a>
                </span>
              </div>
            </ng-container>
            <ng-template #formNotUploaded>
              <div class="box-container">
                <div class="icon-round-bg">
                  <img style="width: 20px; height: 20px;" src="assets/icons/line-icons/shared/grey/upload-cloud.svg" role="presentation">
                </div>
                <p>{{ 'milestones.detail.no-forms' | translate }}</p>
                <a class="box-container__link" (click)="uploadFormClick($event)" href="#">
                  {{ 'milestones.upload-form' | translate }}
                </a>
              </div>
            </ng-template>
          </div>

          <ng-container *featureToggle="'milestone-notification'">
            <div class="section">
              <h5>{{ 'milestones.notification' | translate }}</h5>
              <oes-milestone-notification
                [users]="milestoneNotificationUsersOptions"
                [initialSelections]="milestoneNotificationUsers"
                (selectedUsers)="setSelectedUsers($event)">
              </oes-milestone-notification>
            </div>
          </ng-container>

        </ng-container>
      </form>
    </div>
  </ng-container>
</oes-modal-dialog>

<oes-modal-dialog
  #deleteWarningModal
  id="deleteWarningModal"
  [size]="'small'"
  [allowCancel]="true"
  [allowSubmit]="true"
  [destructive]="true"
  [title]="'milestones.delete-milestone' | translate"
  (submit)="onDelete()"
  [submitButtonText]="'responses.yes' | translate"
  [modalStyle]="'fresh'"
  (close)="deleteWarningModal.hide()">
  {{ 'milestones.delete-modal.warning' | translate }}
</oes-modal-dialog>