<div class="home" data-testid="amadermali-home-container">
  <div class="body">
    <div class="header mt-1 mb-3">
      <img src="/assets/images/amadermali/amader-logo.jpg" width="340px" />
    </div>
    <h3>{{ 'home-amadermali.subheading' | translate }}</h3>
    <div class="image-display">
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/amader-mali-1.jpg" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/amader-mali-3.jpg" />
      <img src="https://oes-hosted-images-public.s3.amazonaws.com/amader-mali-2.jpg" />
    </div>
    <h4 class="mt-3 mb-1">{{ 'themes.partners' | translate }}:</h4>
    <div class="bg-container">
      <div class="logos mb-2">
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/undp-logo.png" width="80px" />
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/sida-logo.png" width="230px" class="ml-2 mr-2" />
        <img src="https://oes-hosted-images-public.s3.amazonaws.com/ecreee-logo.png" width="224px" />
      </div>
    </div>
    <div class="help-banner">
      <h4>{{ 'home.questions-1' | translate }}</h4>
      <p>{{ 'home.questions-2' | translate }}</p>
    </div>
  </div>
</div>
