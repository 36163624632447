import { Injectable } from '@angular/core';
import { MainMenuCategory } from '@main-navigation/shared/main-menu-category.enum';
import { BehaviorSubject, Observable } from 'rxjs';

export type PinnedTabMapping = { [C in MainMenuCategory] : number };

@Injectable({
  providedIn: 'root'
})
export class NavHeaderService {
  private pinnedTabsSubject = new BehaviorSubject<PinnedTabMapping>({
    'plan': 0,
    'finance': 0,
    'build': 0,
    'operate': 0,
    'analytics': 0,
    'crm': 0,
  });
  public pinnedTabs$: Observable<PinnedTabMapping> = this.pinnedTabsSubject.asObservable();

  constructor() {
  }

  set pinnedTabs(obj: PinnedTabMapping) {
    this.pinnedTabsSubject.next(obj);
  }
}
