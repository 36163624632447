import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FORM_TYPE } from "@program/shared/formio-custom-components/form-type.enum";
import { Project } from "@project/shared/project.model";
import { DynamicFormService } from "@shared/components/dynamic-form/dynamic-form.service";
import { DocumentDataService } from "@shared/components/files/shared/document-data.service";
import { ModalDialogComponent } from "@shared/components/modal-dialog/modal-dialog.component";
import { EventService } from "@shared/event.service";
import { User } from "@user/user.model";
import { Subject, take, takeUntil } from "rxjs";
import { ExternalTaskService } from "src/app/task/external-task.service";
import { OesTask } from "src/app/task/task.model";

import { Milestone } from "../../milestone.model";
import { MilestoneService } from "../../milestone.service";
import { ProjectMilestonesService } from "../../project-milestones.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Installer } from "@project/installers/installer.model";
import { Offtaker } from "@project/offtakers/offtaker.model";

@Component({
  selector: 'oes-milestone-content-form',
  templateUrl: './milestone-content-form.component.html',
  styleUrls: ['./milestone-content-form.component.scss'],
})
export class MilestoneContentFormComponent implements OnInit, OnDestroy {
  @ViewChild(ModalDialogComponent, {static: false}) modalDialogComponent: ModalDialogComponent;

  @Input() formJson: any;
  @Input() milestone: Milestone;
  @Input() project: Project;
  @Input() projectInstaller: Installer;
  @Input() projectOfftaker: Offtaker;
  @Input() readOnly: boolean;
  @Input() user: User;
  @Input() externalUser = false;
  @Input() externalUserEmail: string;
  @Input() taskId: string;

  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

  formValid: boolean = false;
  isDirty: boolean = false;
  updateUser: string;
  saveDate: Date;

  private firstChange = true;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _documentDataService: DocumentDataService,
              private _dynamicFormService: DynamicFormService,
              private _eventService: EventService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _externalTaskService: ExternalTaskService,
              private _milestoneService: MilestoneService,
              private _projectMilestonesService: ProjectMilestonesService,
              private _translateService: TranslateService) {
}

  ngOnInit() {
    let orgId;
    if (!this.externalUser) {
      orgId = this.user?.organization?.id;
    } else {
      orgId = this.externalUserEmail;
    }

    this._documentDataService.setFileUploadStorageModel(
      orgId,
      FORM_TYPE.MILESTONE_FORM,
      this.milestone.id,
      this.externalUser
    );
    this.updateUser = this.formJson?.metadata?.updateUser;
    this.saveDate = this.formJson?.metadata?.updated;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  setFormValid(validity: boolean) {
    this.formValid = validity;
    this.valid.emit(validity);
  }

  getMilestoneSavedBy(): string {
    if (this.projectInstaller?.email === this.updateUser && this.projectInstaller?.name?.length) {
      return this.projectInstaller.name;
    }
    if (this.projectOfftaker?.email === this.updateUser && this.projectOfftaker?.name?.length) {
      return this.projectOfftaker.name;
    }
    return this.updateUser;
  }

  fileSave() {
    if (!this.externalUser) {
      this._milestoneService.triggerRefetchMilestones();
    }
  }

  formChanged() {
    if (this.firstChange) {
      this.firstChange = false;
    } else {
      this.isDirty = true;
    }
  }

  formSave(event) {
    this.formJson.metadata = {...this.formJson.metadata, valid: this.formValid };
    if (!this.externalUser) {
      this._projectMilestonesService.saveFormData(
        this.project.id,
        this.milestone.id,
        this._dynamicFormService.updateDefaultValue(this.formJson, event),
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dataResponse) => {
        this.updateUser = dataResponse?.metadata?.updateUser;
        this.saveDate = dataResponse?.metadata?.updated;
        if (event?.component?.properties?.submitAfterSave) {
          this._projectMilestonesService.markExternalTaskCompleted(this.project.id, this.milestone.id)
            .pipe(take(1))
            .subscribe((task: OesTask) => {
              this.formJson = null;
              this.fileSave();
            });
        }
        this._eventService.success(this._translateService.instant('milestones.form.saved'));
        this.isDirty = false;
      });
    } else {
      this._externalTaskService.saveExternalTaskMilestoneFormDate(
        this.taskId,
        this.milestone.id,
        this._dynamicFormService.updateDefaultValue(this.formJson, event),
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((_) => {
        if (event?.component?.properties?.submitAfterSave) {
          this._externalTaskService.submitExternalTaskForm(this.taskId, this.milestone.id)
            .pipe(take(1))
            .subscribe((task: OesTask) => {
              this._router.navigate(['completed'], {relativeTo: this._activatedRoute});
            });
        }

        this._eventService.success(this._translateService.instant('milestones.form.saved'));
        this.isDirty = false;
      });
    }
  }
}
