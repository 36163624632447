// angular modules
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// 3rd party modules
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormioAppConfig, FormioModule } from '@formio/angular';
import { FormioConfig } from './formio-config';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { NouisliderModule } from 'ng2-nouislider';
import { QuillModule } from 'ngx-quill';
import { DragulaModule } from 'ng2-dragula';
import { NgxEchartsModule } from 'ngx-echarts';
import { FileUploadModule } from 'ng2-file-upload';

// OES components
import { ActionButtonComponent } from './ag-grid/component/action-button/action-button.component';
import { FilterPipe } from './pipes/filter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { DecamelCasePipe } from './pipes/decamel-case.pipe';
import { DateTimeSelectorComponent } from './components/date-time-selector/date-time-selector.component';
import { DateSelectorComponent } from './components/date-time-selector/date-selector/date-selector.component';
import { TimeSelectorComponent } from './components/date-time-selector/time-selector/time-selector.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { UnsavedDataModalComponent } from './components/unsaved-data-modal/unsaved-data-modal.component';
import { ProductSelectComponent } from './components/product-select/product-select.component';
import { DeveloperProfileComponent } from './components/developer-profile/developer-profile.component';
import { FilesComponent } from './components/files/files/files.component';
import { FilesActionComponent } from './components/files/files/action/action.component';
import { CreateFolderComponent } from './components/files/files/action-components/create-folder/create-folder.component';
import { CopyComponent } from './components/files/files/action-components/copy/copy.component';
import { DeleteComponent } from './components/files/files/action-components/delete/delete.component';
import { UploaderComponent } from './components/files/files/action-components/uploader/uploader.component';
import { TagAddComponent } from './components/files/files/action-components/tag/add/add.component';
import { TagDeleteComponent } from './components/files/files/action-components/tag/delete/delete.component';
import { AssociationAddComponent } from './components/files/files/action-components/association/add/add.component';
import { AssociationDeleteComponent } from './components/files/files/action-components/association/delete/delete.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { FormioSimpleFormComponent } from './components/dynamic-form/simple-form/simple-form.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PortfolioCapitalStatusComponent } from './components/capital-status/capital-status.component';
import { NumericEditorComponent } from './ag-grid/component/numeric-editor/numeric-editor.component';
import { CellButtonComponent } from './ag-grid/component/cell-button/cell-button.component';
import { CellStatusComponent } from './ag-grid/component/cell-status/cell-status.component';
import { GridStatusCellComponent } from './ag-grid/component/grid-status-cell/grid-status-cell.component';
import { SubmissionStatusComponent } from './ag-grid/component/submission-status/submission-status.component';
import { ViewToggleComponent } from './components/view-toggle-button/view-toggle-button.component';
import { ExcelLoaderComponent } from './components/excel-loader/excel-loader.component';
import { InlineErrorHandlerComponent } from './components/inline-error-handler/inline-error-handler.component';
import { SetFocusDirective } from './directives/set-focus.directive';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { EditNameComponent } from './components/files/files/action-components/edit-name/edit-name.component';
import { MasterDetailComponent } from './ag-grid/component/master-detail/master-detail.component';
import { FloatingFilterButtonComponent } from './components/floating-filter-button/floating-filter-button.component';
import { DeveloperProfilesComponent } from './components/developer-profiles/developer-profiles.component';
import { ProjectSpecificationComponent } from './components/specification/specification.component';
import { ProjectSpecificationFormComponent } from './components/specification-form/specification-form.component';
import { ProjectTariffDialogComponent } from './components/project-tariff-dialog/tariff.component';
import { RevenueAssumptionsEditorComponent } from './components/project-tariff-dialog/revenue-assumptions-editor/revenue-assumptions-editor.component';
import { ProjectBulkUploadDialogComponent } from './components/project-bulk-upload/bulk-upload.component';
import { ProjectUnsavedGuard } from './guard/project/project-unsaved.guard';
import { UtilizationFactorChartComponent } from './components/project-load-chart-scale/utilization-factor-chart/utilization-factor-chart.component';
import { UtilizationFactorComponent } from './components/project-load-chart-scale/utilization-factor.component';
import { ShowScaleLoadComponent } from './components/project-load-chart-scale/show-scale-load/show-scale-load.component';
import { SliderComponent } from './components/project-load-chart-scale/show-scale-load/slider/slider.component';
import { CostEditorComponent } from '@project/shared/cost-editor/cost-editor.component';
import { ShowHideComponent } from './components/show-hide/show-hide.component';
import { GenerationSystemComponentComponent } from './components/project-generation-design/generation-system-component/generation-system-component.component';
import { DateFilterComponent } from './ag-grid/component/date-filter/date-filter.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ProjectMapComponent } from './components/project-map/project-map.component';
import { FormErrorMessageComponent } from './components/form-error-message/form-error-message.component';
import { QuillComponent } from './components/quill/quill.component';
import { VerificationStatusComponent } from './ag-grid/component/verification-status/verification-status.component';
import { DateCellEditorComponent } from './ag-grid/component/date-cell-editor/date-cell-editor.component';
import { AgGridPaginatorComponent } from './ag-grid/component/ag-grid-paginator/ag-grid-paginator.component';
import { CustomerCategoryComponent } from './ag-grid/component/customer-category/customer-category.component';
import { GridSubActionComponent } from './ag-grid/component/sub-action/sub-action.component';
import { RouterModule } from '@angular/router';
import { MapToProjectComponent } from './ag-grid/component/data-integration/map-to-project/map-to-project.component';
import { CellCountrySelectorComponent } from './ag-grid/component/cell-country-selector/cell-country-selector.component';
import { CellRegionSelectorComponent } from './ag-grid/component/cell-region-selector/cell-region-selector.component';
import { CellCreateProjectButtonComponent } from './ag-grid/component/cell-create-project-button/cell-create-project-button.component';
import { InvestorProfilesComponent } from './components/investor-profiles/investor-profiles.component';
import { InvestorProfileComponent } from './components/investor-profile/investor-profile.component';
import { OrganizationListComponent } from './components/organization-list/organization-list.component';
import { VendorProfilesComponent } from './components/vendor-profiles/vendor-profiles.component';
import { VendorProfileComponent } from './components/vendor-profile/vendor-profile.component';
import { FixedNumberValueAccessorDirective } from './directives/fixed-number-value-accessor.directive';
import { ProductOfferingComponent } from '../shared/ag-grid/component/product-offering/product-offering.component';
import { ProductOfferingFilterComponent } from './ag-grid/component/product-offering-filter/product-offering-filter.component';
import { OpenOrganizationProfileComponent } from './ag-grid/component/open-organization-profile/open-organization-profile.component';
import { TemplateDownloadDirective } from './directives/template-download.directive';
import { CountriesMapComponent } from './components/countries-map/countries-map.component';
import { DcCoupledComponent } from './components/project-generation-design/dc-coupled/dc-coupled.component';
import { AcCoupledComponent } from './components/project-generation-design/ac-coupled/ac-coupled.component';
import { GenerationDesignBosComponent } from './components/project-generation-design/bos/bos.component';
import { StageOverviewComponent } from './components/stage-overview/stage-overview.component';
import { CellAdminNotesComponent } from './ag-grid/component/cell-admin-notes/cell-admin-notes.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { PortfolioShareWithInvestorComponent } from './components/share-with-investor/share-with-investor.component';
import { PortfolioSubmitBidComponent } from './components/submit-bid/submit-bid.component';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { FmPortfolioSummaryBoxComponent } from './components/portfolio/portfolio-introduction/summary-box/summary-box.component';
import { FmPortfolioHeaderImageComponent } from './components/portfolio/portfolio-introduction/header-image/header-image.component';
import { FmIntroductionComponent } from './components/portfolio/portfolio-introduction/fm-introduction.component';
import { BusinessPlanProjectSitesComponent } from './components/portfolio/business-plan/default/project-sites/project-sites.component';
import { BusinessPlanCustomersComponent } from './components/portfolio/business-plan/default/customers/customers.component';
import { PortfolioBusinessPlanFormCustomComponent } from './components/portfolio/business-plan/form-custom/form-custom.component';
import { BusinessPlanOtherActivitiesComponent } from './components/portfolio/business-plan/default/other-activities/other-activities.component';
import { BusinessPlanConstructionComponent } from './components/portfolio/business-plan/default/construction/construction.component';
import { BusinessPlanRisksComponent } from './components/portfolio/business-plan/default/risks/risks.component';
import { PortfolioCustomerSummaryComponent } from './components/portfolio/customer-summary/customer-summary.component';
import { PortfolioGenerationSystemComponent } from './components/portfolio/generation-system/generation-system.component';
import { GenerationBlockComponent } from './components/portfolio/generation-system/generation-block/generation-block.component';
import { MyProjectsListComponent } from '@project/my-projects-list/my-projects-list.component';
import { ProjectActionComponent } from '@project/my-projects-list/project-action/project-action.component';
import { SharedProjectsListComponent } from '@project/shared-projects-list/shared-projects-list.component';
import { SharedProjectActionComponent } from '@project/shared-projects-list/shared-project-action/shared-project-action.component';
import { ConnectionsPayoutComponent } from '@project/detail/connections/connections-payout/connections-payout.component';
import { UserAdminComponent } from './components/manage-users/user-admin.component';
import { PayoutEditorComponent } from './ag-grid/component/payout-editor/payout-editor.component';
import { ProjectRoutePermissionsGuard } from './guard/project/project-route-permissions.guard';
import { ReadOnlyDisplayComponent } from './components/read-only-display/read-only-display.component';
import { CellBooleanSelectorComponent } from './ag-grid/component/cell-boolean-selector/cell-boolean-selector.component';
import { StageUpdateBulkComponent } from './components/stage-update-bulk/stage-update-bulk.component';
import { CellUploaderComponent } from './ag-grid/component/cell-uploader/cell-uploader.component';
import { DateCellEditorWithDeleteComponent } from './ag-grid/component/date-cell-editor-with-delete/date-cell-editor-with-delete.component';
import { CellColorPickerComponent } from './ag-grid/component/cell-color-picker/cell-color-picker.component';
import { CellDropdownActionComponent } from './ag-grid/component/cell-dropdown-action/cell-dropdown-action.component';
import { LoadingCellRendererComponent } from './ag-grid/component/loading-cell-renderer/loading-cell-renderer.component';
import { ProcurementHomeComponent } from '@procurement/quote/procurement-home/procurement-home.component';
import { IconCardsComponent } from './components/icon-cards/icon-cards.component';
import { ImageCardsComponent } from './components/image-cards/image-cards.component';
import { FormioFileUploaderComponent } from '@program/shared/formio-custom-components/formio-file-uploader/formio-file-uploader.component';
import { OfftakersListComponent } from '@project/offtakers/offtakers-list.component';
import { OfftakersListActionComponent } from '@project/offtakers/offtakers-list-action/offtakers-list-action.component';
import { OfftakerCreateComponent } from '@project/offtakers/create/offtaker-create.component';
import { ManageOfftakersComponent } from './components/manage-c&i/manage-offtakers/manage-offtakers.component';
import { OfftakerFormComponent } from '@project/offtakers/form/offtaker-form.component';
import { ManageCAndIComponent } from './components/manage-c&i/manage-c&i.component';
import { ManageProjectSetPortfoliosComponent } from './components/manage-c&i/manage-project-set-portfolios/manage-project-set-portfolios.component';
import { ManageProjectSetFinancialModelTemplatesComponent } from './components/manage-c&i/manage-financial-model-templates/manage-project-set-financial-model-templates.component';
import { SiteGroupOverviewComponent } from '@project/offtakers/overview/site-group-overview/site-group-overview.component';
import { ProjectSetEditComponent } from '@project/offtakers/project-set/edit/project-set-edit.component';
import { ProjectSetFinancialModelComponent } from '@project/offtakers/project-set/edit/financial-model/project-set-financial-model.component';
import { OesChartComponent } from '@project/shared/charts/oes-chart.component';
import { StackedBarChartComponent } from '@project/shared/charts/stacked-bar-chart/stacked-bar-chart.component';
import { HorizontalBarChartComponent } from '@project/shared/charts/horizontal-bar-chart/horizontal-bar-chart.component';
import { PieChartComponent } from '@project/shared/charts/pie-chart/pie-chart.component';
import { BarLineChartComponent } from '@project/shared/charts/bar-line-chart/bar-line-chart.component';
import { KeyWithValuesComponent } from '@project/shared/charts/pie-chart/key-with-values/key-with-values.component';
import { ProjectSetDetailsComponent } from '@project/offtakers/project-set/edit/details/project-set-details.component';
import { NgChartsModule } from 'ng2-charts';
import { ProjectSetPortfolioShareWithAnyInvestorComponent } from '@finance/developer-project-set-portfolio/submit/share-with-any-investor/share-with-any-investor.component';
import { ProjectSetPortfolioBusinessPlanFormCustomComponent } from '@finance/developer-project-set-portfolio/business-plan/form-custom/project-set-portfolio-form-custom.component';
import { PortfolioGridStatusCellComponent } from './ag-grid/component/portfolio-grid-status-cell/portfolio-grid-status-cell.component';
import { CiPortfolioListDisplayComponent } from '@finance/portfolio-shared/ci-portfolio-list-display/ci-portfolio-list-display.component';
import { DeveloperProjectSetPortfolioListDisplayComponent } from '@finance/developer-portfolio-list/developer-project-set-portfolio-list-display/developer-project-set-portfolio-list-display.component';
import { DisplayProgramDateTimeComponent } from '@finance/developer-portfolio/shared/display-program-date-time/display-program-date-time.component';
import { ProjectSetPortfolioBusinessPlanComponent } from '@finance/developer-project-set-portfolio/business-plan/project-set-portfolio-business-plan.component';
import { ProjectSetPortfolioDataRoomComponent } from '@finance/developer-project-set-portfolio/data-room/project-set-portfolio-data-room.component';
import { ProjectSetPortfolioOverviewComponent } from '@finance/developer-project-set-portfolio/developer-project-set-portfolio-overview/developer-project-set-portfolio-overview.component';
import { DisplayPSPOverviewMetricsComponent } from '@finance/developer-project-set-portfolio/developer-project-set-portfolio-overview/display-psp-overview-metrics/display-psp-overview-metrics.component';
import { ProjectSetPortfolioProfileComponent } from '@finance/developer-project-set-portfolio/developer-project-set-portfolio-profile/developer-project-set-portfolio-profile.component';
import { ProjectSetPortfolioNavigationComponent } from '@finance/developer-project-set-portfolio/project-set-portfolio-navigation/project-set-portfolio-navigation.component';
import { ProjectSetPortfolioSubmitComponent } from '@finance/developer-project-set-portfolio/submit/project-set-portfolio-submit.component';
import { NavHeaderComponent } from '../nav-header/nav-header.component';
import { ProcureBannerAdComponent } from './components/procure-banner-ad/procure-banner-ad.component';
import { FileTagFilterComponent } from './ag-grid/component/file-tag-filter/file-tag-filter.component';
import { GlobalFilesComponent } from './components/files/global-files/global-files.component';
import { ProgramHeaderComponent } from '@program/program-header/program-header.component';
import { CollapsibleComponent } from './components/collapsible/collapsible.component';
import { FmPortfolioListComponent } from '@finance/investor-portfolio-list/fm-portfolio-list/fm-portfolio-list.component';
import { ProjectHeaderComponent } from '@project/detail/project-header/project-header.component';
import { ProjectHeaderTagsComponent } from '@project/detail/project-header-tags/project-header-tags.component';
import { ArchwizardModule } from '@nubebytes/angular-archwizard';
import { EvaluationComponent } from '@program/shared/evaluation/evaluation.component';
import { EvaluationQuestionComponent } from '@program/shared/evaluation/evaluation-question/evaluation-question.component';
import { EvaluationsAdminAllComponent } from '@program/shared/evaluations-admin-all/evaluations-admin-all.component';
import { EvaluationsDisplayComponent } from '@program/shared/evaluations-admin-all/evaluation-display/evaluations-display.component';
import { CriteriaDisplayComponent } from '@program/shared/evaluations-admin-all/criteria-display/criteria-display.component';
import { CellAuditScoreComponent } from './ag-grid/component/cell-audit-score/cell-audit-score.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { ButtonComponent } from './components/button/button.component';
import { GridItemComponent } from './ag-grid/component/grid-item/grid-item.component';
import { StatusLabelComponent } from './components/status-label/status-label.component';
import { ManageOrganizationFinancialModelsComponent } from './components/manage-c&i/manage-organization-financial-models/manage-organization-financial-models.component';
import { AgHorizontalScrollDirective } from './directives/ag-horizontal-scroll.directive';
import { AgColumnsDirective } from './directives/ag-columns.directive';
import { SummaryCiKpisComponent } from '@project/detail/summary/summary-ci-kpis/summary-ci-kpis.component';
import { MilestoneListComponent } from '@project/detail/milestones/list/milestone-list.component';
import { MilestoneEditComponent } from '@project/detail/milestones/edit/milestone-edit.component';
import { MilestoneConfigComponent } from '@project/detail/milestones/config/milestone-config.component';
import { OverviewActionsComponent } from '@project/detail/project-overview/overview-actions/overview-actions.component';
import { NotifyComponent } from './components/notify/notify.component';
import { OverviewProgramHeaderComponent } from '@project/detail/project-overview/overview-program-header/overview-program-header.component';
import { AssignProjectDeveloperComponent } from '../project/detail/project-overview/assign-project-developer/assign-project-developer.component';
import { SideDrawerComponent } from './components/drawer/side-drawer.component';
import { CellButtonGenericComponent } from './ag-grid/component/cell-button-generic/cell-button-generic.component';
import { MilestoneRowComponent } from '@project/detail/milestones/row/milestone-row.component';
import { ProjectMilestonesComponent } from '@project/detail/milestones/project-milestones.component';
import { AssignProjectInstallerComponent } from '../project/detail/project-overview/assign-project-installer/assign-project-installer.component';
import { InstallerListComponent } from '@project/installers/list/installer-list.component';
import { InstallerFormComponent } from '@project/installers/form/installer-form.component';
import { AssignProjectOfftakerComponent } from '../project/detail/project-overview/assign-project-offtaker/assign-project-offtaker.component';
import { MilestoneViewComponent } from '@project/detail/milestones/view/milestone-view.component';
import { MilestoneContentNotesComponent } from '@project/detail/milestones/content/notes/milestone-content-notes.component';
import { MilestoneContentFormComponent } from '@project/detail/milestones/content/forms/milestone-content-form.component';
import { MilestoneShareComponent } from '@project/detail/milestones/share/milestone-share.component';
import { MilestoneNotificationComponent } from '@project/detail/milestones/notification/milestone-notification.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    NouisliderModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxBootstrapMultiselectModule,
    FormioModule,
    FeatureToggleModule,
    GoogleMapsModule,
    ArchwizardModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          [
            'bold',
            'italic',
            'underline',
            { list: 'ordered' },
            { list: 'bullet' },
            'link',
            'image',
            'video',
          ],
        ],
      },
    }),
    DragulaModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NgChartsModule,
  ],
  declarations: [
    MyProjectsListComponent,
    OfftakersListComponent,
    ProjectActionComponent,
    OfftakersListActionComponent,
    SharedProjectsListComponent,
    SharedProjectActionComponent,
    ActionButtonComponent,
    NotifyComponent,
    DecamelCasePipe,
    FilterPipe,
    SafeHtmlPipe,
    CamelCasePipe,
    SafeUrlPipe,
    DateTimeSelectorComponent,
    DateSelectorComponent,
    TimeSelectorComponent,
    ModalDialogComponent,
    DropdownComponent,
    UnsavedDataModalComponent,
    ProductSelectComponent,
    DeveloperProfileComponent,
    FilesComponent,
    GlobalFilesComponent,
    FilesActionComponent,
    FileTagFilterComponent,
    CreateFolderComponent,
    CopyComponent,
    DeleteComponent,
    UploaderComponent,
    TagAddComponent,
    TagDeleteComponent,
    AssociationAddComponent,
    AssociationDeleteComponent,
    BusinessPlanProjectSitesComponent,
    BusinessPlanCustomersComponent,
    PortfolioBusinessPlanFormCustomComponent,
    ProjectSetPortfolioBusinessPlanFormCustomComponent,
    DynamicFormComponent,
    FormioSimpleFormComponent,
    BusinessPlanOtherActivitiesComponent,
    BusinessPlanConstructionComponent,
    BusinessPlanRisksComponent,
    PortfolioCapitalStatusComponent,
    PortfolioCustomerSummaryComponent,
    PortfolioGenerationSystemComponent,
    GenerationBlockComponent,
    NumericEditorComponent,
    PayoutEditorComponent,
    CellStatusComponent,
    LoadingCellRendererComponent,
    CellButtonComponent,
    CellDropdownActionComponent,
    CellUploaderComponent,
    CellBooleanSelectorComponent,
    CellColorPickerComponent,
    SubmissionStatusComponent,
    GridStatusCellComponent,
    PortfolioGridStatusCellComponent,
    MasterDetailComponent,
    MapToProjectComponent,
    ViewToggleComponent,
    ExcelLoaderComponent,
    InlineErrorHandlerComponent,
    SetFocusDirective,
    FixedNumberValueAccessorDirective,
    ToggleSwitchComponent,
    EditNameComponent,
    FloatingFilterButtonComponent,
    VerificationStatusComponent,
    DeveloperProfilesComponent,
    ProjectSpecificationComponent,
    ProjectSpecificationFormComponent,
    ProjectTariffDialogComponent,
    ConnectionsPayoutComponent,
    RevenueAssumptionsEditorComponent,
    ProjectBulkUploadDialogComponent,
    ShowScaleLoadComponent,
    SliderComponent,
    UtilizationFactorChartComponent,
    UtilizationFactorComponent,
    CostEditorComponent,
    ShowHideComponent,
    GenerationSystemComponentComponent,
    DateFilterComponent,
    ProjectMapComponent,
    FormErrorMessageComponent,
    QuillComponent,
    DateCellEditorComponent,
    DateCellEditorWithDeleteComponent,
    AgGridPaginatorComponent,
    CustomerCategoryComponent,
    GridSubActionComponent,
    CellCountrySelectorComponent,
    CellRegionSelectorComponent,
    CellCreateProjectButtonComponent,
    InvestorProfilesComponent,
    InvestorProfileComponent,
    VendorProfilesComponent,
    VendorProfileComponent,
    ProcurementHomeComponent,
    IconCardsComponent,
    ImageCardsComponent,
    OrganizationListComponent,
    ProductOfferingComponent,
    ProductOfferingFilterComponent,
    OpenOrganizationProfileComponent,
    TemplateDownloadDirective,
    CountriesMapComponent,
    AcCoupledComponent,
    DcCoupledComponent,
    GenerationDesignBosComponent,
    StageOverviewComponent,
    StageUpdateBulkComponent,
    CellAdminNotesComponent,
    CellAuditScoreComponent,
    BreadcrumbComponent,
    PortfolioShareWithInvestorComponent,
    ProjectSetPortfolioShareWithAnyInvestorComponent,
    PortfolioSubmitBidComponent,
    FmIntroductionComponent,
    FmPortfolioSummaryBoxComponent,
    FmPortfolioHeaderImageComponent,
    UserAdminComponent,
    ManageOfftakersComponent,
    ManageProjectSetPortfoliosComponent,
    ManageProjectSetFinancialModelTemplatesComponent,
    ManageOrganizationFinancialModelsComponent,
    ManageCAndIComponent,
    ReadOnlyDisplayComponent,
    FormioFileUploaderComponent,
    OfftakerCreateComponent,
    SiteGroupOverviewComponent,
    ProjectSetEditComponent,
    ProjectSetFinancialModelComponent,
    OesChartComponent,
    StackedBarChartComponent,
    HorizontalBarChartComponent,
    PieChartComponent,
    BarLineChartComponent,
    KeyWithValuesComponent,
    ProjectSetDetailsComponent,
    CiPortfolioListDisplayComponent,
    DeveloperProjectSetPortfolioListDisplayComponent,
    DisplayProgramDateTimeComponent,
    ProjectSetPortfolioBusinessPlanComponent,
    ProjectSetPortfolioDataRoomComponent,
    ProjectSetPortfolioOverviewComponent,
    DisplayPSPOverviewMetricsComponent,
    ProjectSetPortfolioProfileComponent,
    ProjectSetPortfolioNavigationComponent,
    ProjectSetPortfolioSubmitComponent,
    NavHeaderComponent,
    ProgramHeaderComponent,
    ProjectHeaderComponent,
    ProjectHeaderTagsComponent,
    ProcureBannerAdComponent,
    CollapsibleComponent,
    FmPortfolioListComponent,
    ButtonComponent,
    GridItemComponent,
    StatusLabelComponent,
    EvaluationComponent,
    EvaluationsAdminAllComponent,
    CriteriaDisplayComponent,
    EvaluationsDisplayComponent,
    EvaluationQuestionComponent,
    TextInputComponent,
    AgHorizontalScrollDirective,
    AgColumnsDirective,
    SummaryCiKpisComponent,
    MilestoneListComponent,
    MilestoneViewComponent,
    MilestoneEditComponent,
    MilestoneShareComponent,
    MilestoneConfigComponent,
    MilestoneNotificationComponent,
    OverviewActionsComponent,
    OverviewProgramHeaderComponent,
    AssignProjectDeveloperComponent,
    SideDrawerComponent,
    CellButtonGenericComponent,
    ProjectMilestonesComponent,
    MilestoneRowComponent,
    MilestoneContentNotesComponent,
    InstallerListComponent,
    InstallerFormComponent,
    AssignProjectInstallerComponent,
    OfftakerFormComponent,
    AssignProjectOfftakerComponent,
    MilestoneContentFormComponent,
  ],
  exports: [
    CommonModule,
    FileUploadModule,
    TranslateModule,
    AgGridModule,
    NotifyComponent,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    AccordionModule,
    BsDatepickerModule,
    TimepickerModule,
    ModalModule,
    TooltipModule,
    PaginationModule,
    BsDropdownModule,
    NouisliderModule,
    DecamelCasePipe,
    FilterPipe,
    SafeHtmlPipe,
    CamelCasePipe,
    SafeUrlPipe,
    FormioModule,
    FeatureToggleModule,
    NgxBootstrapMultiselectModule,
    GoogleMapsModule,
    ArchwizardModule,
    QuillModule,
    DragulaModule,
    NgxEchartsModule,
    // OES
    ActionButtonComponent,
    DateTimeSelectorComponent,
    DateSelectorComponent,
    TimeSelectorComponent,
    ModalDialogComponent,
    DropdownComponent,
    UnsavedDataModalComponent,
    ProductSelectComponent,
    DeveloperProfileComponent,
    FilesComponent,
    GlobalFilesComponent,
    BusinessPlanProjectSitesComponent,
    BusinessPlanCustomersComponent,
    PortfolioBusinessPlanFormCustomComponent,
    ProjectSetPortfolioBusinessPlanFormCustomComponent,
    DynamicFormComponent,
    FormioSimpleFormComponent,
    BusinessPlanOtherActivitiesComponent,
    BusinessPlanConstructionComponent,
    BusinessPlanRisksComponent,
    PortfolioCapitalStatusComponent,
    PortfolioCustomerSummaryComponent,
    PortfolioGenerationSystemComponent,
    GenerationBlockComponent,
    NumericEditorComponent,
    PayoutEditorComponent,
    CellStatusComponent,
    CellButtonComponent,
    CellDropdownActionComponent,
    CellUploaderComponent,
    CellBooleanSelectorComponent,
    CellColorPickerComponent,
    SubmissionStatusComponent,
    GridStatusCellComponent,
    PortfolioGridStatusCellComponent,
    LoadingCellRendererComponent,
    MasterDetailComponent,
    MapToProjectComponent,
    ViewToggleComponent,
    ExcelLoaderComponent,
    InlineErrorHandlerComponent,
    SetFocusDirective,
    FixedNumberValueAccessorDirective,
    ToggleSwitchComponent,
    FloatingFilterButtonComponent,
    VerificationStatusComponent,
    ProjectSpecificationComponent,
    ProjectSpecificationFormComponent,
    ProjectTariffDialogComponent,
    ConnectionsPayoutComponent,
    RevenueAssumptionsEditorComponent,
    ProjectBulkUploadDialogComponent,
    ShowScaleLoadComponent,
    SliderComponent,
    UtilizationFactorChartComponent,
    UtilizationFactorComponent,
    CostEditorComponent,
    ShowHideComponent,
    GenerationSystemComponentComponent,
    DateFilterComponent,
    ProjectMapComponent,
    FormErrorMessageComponent,
    QuillComponent,
    DateCellEditorComponent,
    DateCellEditorWithDeleteComponent,
    AgGridPaginatorComponent,
    CustomerCategoryComponent,
    GridSubActionComponent,
    CellCountrySelectorComponent,
    CellRegionSelectorComponent,
    CellCreateProjectButtonComponent,
    InvestorProfilesComponent,
    InvestorProfileComponent,
    VendorProfilesComponent,
    VendorProfileComponent,
    ProcurementHomeComponent,
    IconCardsComponent,
    ImageCardsComponent,
    OrganizationListComponent,
    ProductOfferingComponent,
    ProductOfferingFilterComponent,
    OpenOrganizationProfileComponent,
    TemplateDownloadDirective,
    CountriesMapComponent,
    AcCoupledComponent,
    DcCoupledComponent,
    GenerationDesignBosComponent,
    StageOverviewComponent,
    StageUpdateBulkComponent,
    CellAdminNotesComponent,
    CellAuditScoreComponent,
    BreadcrumbComponent,
    PortfolioShareWithInvestorComponent,
    ProjectSetPortfolioShareWithAnyInvestorComponent,
    PortfolioSubmitBidComponent,
    FmIntroductionComponent,
    FmPortfolioSummaryBoxComponent,
    FmPortfolioHeaderImageComponent,
    MyProjectsListComponent,
    OfftakersListComponent,
    ProjectActionComponent,
    OfftakersListActionComponent,
    SharedProjectsListComponent,
    SharedProjectActionComponent,
    UserAdminComponent,
    ManageOfftakersComponent,
    ManageCAndIComponent,
    ManageProjectSetPortfoliosComponent,
    ReadOnlyDisplayComponent,
    FormioFileUploaderComponent,
    OfftakerCreateComponent,
    SiteGroupOverviewComponent,
    ProjectSetEditComponent,
    ProjectSetFinancialModelComponent,
    OesChartComponent,
    StackedBarChartComponent,
    HorizontalBarChartComponent,
    PieChartComponent,
    BarLineChartComponent,
    KeyWithValuesComponent,
    ProjectSetDetailsComponent,
    CiPortfolioListDisplayComponent,
    DeveloperProjectSetPortfolioListDisplayComponent,
    DisplayProgramDateTimeComponent,
    ProjectSetPortfolioBusinessPlanComponent,
    ProjectSetPortfolioDataRoomComponent,
    ProjectSetPortfolioOverviewComponent,
    DisplayPSPOverviewMetricsComponent,
    ProjectSetPortfolioProfileComponent,
    ProjectSetPortfolioNavigationComponent,
    ProjectSetPortfolioSubmitComponent,
    NavHeaderComponent,
    ProgramHeaderComponent,
    ProjectHeaderComponent,
    ProjectHeaderTagsComponent,
    ProcureBannerAdComponent,
    FmPortfolioListComponent,
    EvaluationComponent,
    EvaluationsAdminAllComponent,
    CriteriaDisplayComponent,
    EvaluationsDisplayComponent,
    EvaluationQuestionComponent,
    CollapsibleComponent,
    FmPortfolioListComponent,
    ButtonComponent,
    GridItemComponent,
    StatusLabelComponent,
    TextInputComponent,
    AgHorizontalScrollDirective,
    AgColumnsDirective,
    SummaryCiKpisComponent,
    MilestoneListComponent,
    MilestoneViewComponent,
    MilestoneEditComponent,
    MilestoneShareComponent,
    MilestoneConfigComponent,
    MilestoneNotificationComponent,
    OverviewActionsComponent,
    OverviewProgramHeaderComponent,
    AssignProjectDeveloperComponent,
    SideDrawerComponent,
    CellButtonGenericComponent,
    ProjectMilestonesComponent,
    MilestoneRowComponent,
    MilestoneContentNotesComponent,
    AssignProjectInstallerComponent,
    InstallerListComponent,
    InstallerFormComponent,
    OfftakerFormComponent,
    AssignProjectOfftakerComponent,
    MilestoneContentFormComponent,
  ],
  providers: [
    CurrencyPipe,
    ProjectUnsavedGuard,
    ProjectRoutePermissionsGuard,
    {
      provide: FormioAppConfig,
      useValue: FormioConfig,
    },
  ],
})
export class SharedModule {}
