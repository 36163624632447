<oes-dropdown
  [parentFormGroup]="formGroup"
  [items]="displayUsers"
  [initialSelections]="selectedDisplayUsers"
  [controlName]="'usersControl'"
  [customText]="customText"
  name="type"
  [selection]="'multiple'">
</oes-dropdown>

<div class="users-list">
  <h5 *ngIf="selectedDisplayUsers?.length">{{ 'milestones.to-be-notified' | translate }}</h5>
  <ng-container *ngFor="let user of selectedDisplayUsers">
    <p>{{ user.name }}</p>
  </ng-container>
</div>
