import { BaseModel } from '@shared/base.model';
import { Organization } from '@shared/models/organization/organization.model';
import { OesTaskType } from './task-type.enum';

export class OesTask extends BaseModel<OesTask> {
  organization: Organization;
  type: OesTaskType;
  index: string;  // id of related TaskType from above
  indexObject: any;  // entire object related to index above - transient
  expirationDateTime: Date;
  emailAddress: string;
  text: string;
  completedDateTime: Date;

  constructor(taskInfo: any) {
    super();
    this.id = taskInfo.id;
    this.organization = taskInfo.organization;
    this.type = taskInfo.type;
    this.index = taskInfo.index;
    this.indexObject = taskInfo.indexObject;
    this.expirationDateTime = taskInfo.expirationDateTime;
    this.emailAddress = taskInfo.emailAddress;
    this.text = taskInfo.text;
    this.completedDateTime = taskInfo.completedDateTime;
  }
}
