<div class="grid-pagination" [ngStyle]="{'margin-top': totalItems ? 0 : '100px'}">
  <div class="total">
    {{beginItemNumber}} {{'pagination.to' | translate}} {{endItemNumber}}<span *ngIf="showTotalItems">/{{totalItems}}</span>
  </div>
  <div class="page-size-paginator">
    <form [formGroup]="formGroup">
      <div class="select-wrapper">
        <select
          class="form-control"
          formControlName="pageSizeControl">
          <option
            *ngFor="let pageSizeOption of pageSizeOptions"
            [value]="pageSizeOption.value">
            {{pageSizeOption.name}}
          </option>
        </select>
      </div>
    </form>
    <pagination
      *ngIf="totalItems"
      [totalItems]="totalItems"
      [itemsPerPage]="pageStatus?.pageSize"
      [maxSize]="maxSize"
      [boundaryLinks]="true"
      (pageChanged)="pageChanged($event)"
      firstText="&laquo; {{'pagination.first' | translate}}"
      previousText="&lsaquo; {{'pagination.previous' | translate}}"
      nextText="{{'pagination.next' | translate}} &rsaquo;"
      lastText="{{'pagination.last' | translate}} &raquo;">
    </pagination>
  </div>
</div>
