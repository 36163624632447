import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { User } from '@user/user.model';
import { IMultiSelectTexts } from 'ngx-bootstrap-multiselect';

@Component({
  selector: 'oes-milestone-notification',
  templateUrl: './milestone-notification.component.html',
  styleUrls: ['./milestone-notification.component.scss'],
})
export class MilestoneNotificationComponent implements OnChanges {
  @Input() users: User[];
  @Input() initialSelections: User[];

  @Output() selectedUsers: EventEmitter<User[]> = new EventEmitter();

  customText: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Search',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Select one or more users from you organization',
    allSelected: 'All selected',
  };
  displayUsers = [];
  formGroup: FormGroup;
  selectedDisplayUsers = [];

  constructor() {};

  ngOnChanges(changes: SimpleChanges): void {
    this.initialize();
  }

  private initialize() {
    this.createForm();
    if (this.users) {
      this.displayUsers = this.mapUsersForDisplay(this.users);
    }
    if (this.initialSelections) {
      this.selectedDisplayUsers = this.mapUsersForDisplay(this.initialSelections);
    }
  }

  private mapUsersForDisplay(users: User[]) {
    return users.map((user: User, index) => {
      return {
        name: user.emailAddress ? user.emailAddress : user.username,
        index: index,
        id: user?.id
      };
    });
  }

  private createForm() {
    this.formGroup = new FormGroup({
      usersControl: new FormControl<User[]>(null)
    });
    this.subscribeForm();
  }

  private subscribeForm() {
    this.formGroup.valueChanges.subscribe((values) => {
      const selectedUsers = this.users.filter(user => {
        if (values.usersControl.includes(user.id)) {
          return user;
        }
      });
      this.selectedUsers.next(selectedUsers);
    });
  }

};
