import { Location } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MILESTONE_STATUS, MILESTONE_STATUS_LABEL } from "@program/shared/milestone-status.enum";
import { Installer } from "@project/installers/installer.model";
import { Offtaker } from "@project/offtakers/offtaker.model";
import { Project } from "@project/shared/project.model";
import { Document } from '@shared/components/files/shared/models/document.model';
import { Subscription } from "rxjs";

import { MilestoneStatus } from "../milestone-status.model";
import { MILESTONE_TYPE } from "../milestone-type.enum";
import { Milestone } from "../milestone.model";
import { MilestoneService } from "../milestone.service";

@Component({
  selector: 'oes-milestone-row',
  templateUrl: './milestone-row.component.html',
  styleUrls: ['./milestone-row.component.scss'],
})
export class MilestoneRowComponent implements OnInit, OnDestroy {
  @Input() index: number;
  @Input() milestoneId: string;
  @Input() milestoneType: MILESTONE_TYPE;
  @Input() project: Project;
  @Input() projectInstaller: Installer;
  @Input() projectOfftaker: Offtaker;
  @Input() statusDropdownOptions: any[];

  documentsToDisplay: Document[];
  initialStatusSelection: any;
  milestone: Milestone = new Milestone({});
  milestoneForm: any;
  milestoneStatus: MilestoneStatus;
  milestoneTypes = MILESTONE_TYPE;
  milestoneViewAfterAnimation = false;
  milestoneViewShowing: boolean;

  private deleteMilestoneSubscription: Subscription;
  private getMilestoneSubscription: Subscription;
  private programMilestoneDataSubscription: Subscription;
  private projectMilestoneDataSubscription: Subscription;


  constructor(private _milestoneService: MilestoneService,
              private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private _location: Location,
              private _translateService: TranslateService) {
    this.statusChanged = this.statusChanged.bind(this);

  }

  ngOnInit() {
    this.deleteMilestoneSubscription = this._milestoneService.deleteMilestone$.subscribe(_ => {
      this.milestoneViewShowing = false;
    });
    this.getMilestoneSubscription = this._milestoneService.getMilestoneByIdWithForm(this.milestoneId)
    .subscribe(({ milestone, form }) => {
      if (!milestone) {
        return;
      }
      this.milestone = milestone;
      this.milestoneForm = form;
      this.initialStatusSelection = this.findInitialStatusDropdownSelection(this.milestone.status);
      this.milestoneStatus = new MilestoneStatus(this.milestone.id, this.milestone.status);
    });
    this.documentsToDisplay = this.milestone.documents.filter(doc =>
      (doc.documentKey.docType as unknown) === 'FILE'
    );
    this._activatedRoute.queryParamMap.subscribe(params => {
      const routeMilestoneId = params.get('milestoneId');
      if (routeMilestoneId === this.milestoneId) {
        this.openMilestoneView();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.deleteMilestoneSubscription) {
      this.deleteMilestoneSubscription.unsubscribe();
    }
    if (this.programMilestoneDataSubscription) {
      this.programMilestoneDataSubscription.unsubscribe();
    }
    if (this.projectMilestoneDataSubscription) {
      this.projectMilestoneDataSubscription.unsubscribe();
    }
    if (this.getMilestoneSubscription) {
      this.getMilestoneSubscription.unsubscribe();
    }
  }

  statusChanged(event) {
    if (event[0].value && event[0].value !== this.milestone?.status) {
      this.milestone = new Milestone({ ...this.milestone, status: event[0].value });
      this._milestoneService.updateMilestone(this.milestone);
      this.milestoneStatus = new MilestoneStatus(this.milestone?.id, this.milestone?.status);
      this.initialStatusSelection = this.findInitialStatusDropdownSelection(this.milestone?.status);
      this.milestoneStatus = new MilestoneStatus(this.milestone?.id, this.milestone?.status);
    }
  }

  findInitialStatusDropdownSelection(name: MILESTONE_STATUS): any {
    const translatedName = this._translateService.instant(MILESTONE_STATUS_LABEL[name]);
    return [this.statusDropdownOptions.find(status => {
      return status.name === translatedName;
    })];
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  openMilestoneView() {
    this.milestoneViewShowing = true;
    this.milestoneViewAfterAnimation = true;

    const currentUrl = this._router.createUrlTree([], {
      relativeTo: this._activatedRoute,
      queryParams: { milestoneId: this.milestoneId },
      queryParamsHandling: 'merge',
    }).toString();

    this._location.replaceState(currentUrl);
  }

  closeMilestoneView() {
    this.milestoneViewShowing = false;
    setTimeout(() => {
      this.milestoneViewAfterAnimation = false;
      const currentUrl = this._router.createUrlTree([], {
        relativeTo: this._activatedRoute,
        queryParams: {},
        queryParamsHandling: '',
      }).toString();
      this._location.replaceState(currentUrl);
    });
  }
}
