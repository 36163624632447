import { environment } from '@environments/environment';

export class ExternalTaskApiConstants {
  public static externalTasks = environment.serverUri + `api/${environment.apiVersion}/external-tasks`;

  public static externalTask = {
    detail: (taskId: string) => {
      return `${ExternalTaskApiConstants.externalTasks}/${taskId}`;
    }
  };

  public static milestone = {
    getForm: (taskId: string, milestoneId: string) => {
      return `${ExternalTaskApiConstants.externalTasks}/${taskId}/milestones/${milestoneId}/form`;
    },
    saveForm: (taskId: string, milestoneId: string) => {
      return `${ExternalTaskApiConstants.externalTasks}/${taskId}/milestones/${milestoneId}/form`;
    },
    submitForm: (taskId: string, milestoneId: string) => {
      return `${ExternalTaskApiConstants.externalTasks}/${taskId}/milestones/${milestoneId}/form/submit`;
    }
  };
}
