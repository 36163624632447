<div class="task">
  <div class="task-container">
    <ng-container *ngIf="logoUri && !imageError">
      <img class="img-fluid"
           [src]="logoUri"
           (error)="imageError = true">
    </ng-container>

    <div *ngIf="formJson">
      <oes-milestone-content-form
        [formJson]="formJson"
        [milestone]="milestone"
        [project]="project"
        [taskId]="task?.id"
        [readOnly]="false"
        [user]="user"
        [externalUser]="true"
        [externalUserEmail]="task?.emailAddress"
      ></oes-milestone-content-form>
    </div>
  </div>
</div>
