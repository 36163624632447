import { MILESTONE_CONTENT_TYPE } from '@project/detail/milestones/milestone-type.enum';
import { Project } from '@project/shared/project.model';
import { BaseModel } from '@shared/base.model';
import { Organization } from '@shared/models/organization/organization.model';
import { User } from '@user/user.model';

import { MILESTONE_STATUS } from './milestone-status.enum';
import { Program } from './program.model';

export class MilestoneConfig extends BaseModel<MilestoneConfig> {
  ownerOrganization: Organization;
  program: Program;
  project: Project;
  statuses: MILESTONE_STATUS[];
  name: string;
  index: number;
  description: string;
  contentType: MILESTONE_CONTENT_TYPE;
  notificationUsers: User[];
  notificationBaseUrl: string;
  showToDevelopers: boolean;
  form?: any;
  duplicateName?: boolean;  // Transient (frontend only)

  constructor(milestoneConfigInfo: any) {
    super();

    this.id = milestoneConfigInfo.id;
    this.ownerOrganization = milestoneConfigInfo.ownerOrganization;
    this.program = milestoneConfigInfo.program;
    this.project = milestoneConfigInfo.project;
    this.statuses = milestoneConfigInfo.statuses;
    this.name = milestoneConfigInfo.name;
    this.index = milestoneConfigInfo.index;
    this.description = milestoneConfigInfo.description;
    this.notificationUsers = milestoneConfigInfo.notificationUsers;
    this.notificationBaseUrl = milestoneConfigInfo.notificationBaseUrl;
    this.showToDevelopers = milestoneConfigInfo.showToDevelopers;
    this.contentType = milestoneConfigInfo.contentType;
    this.form = milestoneConfigInfo.form;
  }
}
