import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Installer } from '@project/installers/installer.model';
import { InstallerService } from '@project/installers/installer.service';
import { Offtaker } from '@project/offtakers/offtaker.model';
import { OfftakerService } from '@project/offtakers/offtaker.service';
import { Project } from '@project/shared/project.model';
import { ProjectService } from '@project/shared/project.service';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { EventService } from '@shared/event.service';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { forkJoin, Subscription, take } from 'rxjs';
import { OesTaskType } from 'src/app/task/task-type.enum';
import { OesTask } from 'src/app/task/task.model';
import { TaskService } from 'src/app/task/task.service';

import { Milestone } from '../milestone.model';
import { MilestoneService } from '../milestone.service';

export enum ShareMilestoneCategory {
  'INSTALLER' = 'INSTALLER',
  'OFFTAKER' = 'OFFTAKER',
  'OTHER' = 'OTHER'
}

@Component({
  selector: 'oes-milestone-share',
  templateUrl: './milestone-share.component.html',
  styleUrls: ['./milestone-share.component.scss'],
})
export class MilestoneShareComponent implements OnInit, OnDestroy {
  @ViewChild('shareMilestoneModal', {static: false}) shareMilestoneModal: ModalDialogComponent;

  installer: Installer;
  milestone: Milestone;
  modalShowing: boolean;
  offtaker: Offtaker;
  project: Project;
  radioSelected = {
    INSTALLER: true,
    OFFTAKER: false,
    OTHER: false
  };
  readOnly: boolean;
  shareForm: FormGroup;
  user: User;

  private openMilestoneShareSubscription: Subscription;

  constructor(private _milestoneService: MilestoneService,
              private _installerService: InstallerService,
              private _offtakerService: OfftakerService,
              private _taskService: TaskService,
              private _userService: UserService,
              private _eventService: EventService,
              private _translateService: TranslateService,
              private _projectService: ProjectService) {
  };

  ngOnInit(): void {
    this._userService.getCurrentUser()
      .pipe(take(1))
      .subscribe((user: User) => {
        this.user = user;
        this.openMilestoneShareSubscription = this._milestoneService.openMilestoneShare$
        .subscribe((milestone: Milestone) => {
          if (!milestone) {
            return;
          }
          this.milestone = milestone;
          this.handleOpenShareMilestone();
        });
      });

    this.project = this._projectService.project;
    const modulePermissions = this.project?.projectPermissions?.project?.modulePermissions;
    this.readOnly = modulePermissions?.milestones?.readOnly;
  };

  ngOnDestroy(): void {
    if (this.openMilestoneShareSubscription) {
      this.openMilestoneShareSubscription.unsubscribe();
    }
  }

  handleOpenShareMilestone() {
    this.initializeModal();
  }

  private initializeModal() {
    const reqs = [
      this._installerService.listInstallersByOrgId(this.user?.organization?.id),
      this._offtakerService.listByOrgId(this.user?.organization?.id)
    ];
    forkJoin(reqs)
      .subscribe((responses: any) => {
        const installers = responses[0];
        const offtakers = responses[1];

        this.installer = installers.filter(inst => {
          const projectInstaller = inst.projectInstallers.find(pi => pi.project.id === this.project.id);
          return projectInstaller !== null && projectInstaller !== undefined;
        })[0];

        this.offtaker = offtakers.filter(off => {
          const projectOfftaker = off.projectOfftakers.find(po => po.project.id === this.project.id);
          return projectOfftaker !== null && projectOfftaker !== undefined;
        })[0];

        this.createShareForm();
        this.shareMilestoneModal.show();
        this.modalShowing = true;
      });
  }

  private createShareForm() {
    this.shareForm = new UntypedFormGroup({
      category: new UntypedFormControl(ShareMilestoneCategory.INSTALLER, Validators.required),
      otherEmail: new FormControl<String>(null, Validators.email),
      emailText: new FormControl<String>(null)
    });

    this.shareForm.valueChanges.subscribe(values => {
      const keys = Object.keys(this.radioSelected);
      keys.forEach(key => {
        this.radioSelected[key] = (key === values?.category);
      });
      if (!this.radioSelected.OTHER) {
        this.shareForm.controls.otherEmail.setValue(null, { emitEvent: false });
      }
    });
  }

  handleCloseShareMilestone() {
    this.modalShowing = false;
    this.shareMilestoneModal.hide();
    this.clearForm();
  }

  private clearForm() {
    this.shareForm.controls.category.setValue(ShareMilestoneCategory.INSTALLER, { emitEvent: false });
    this.shareForm.controls.otherEmail.setValue(null, { emitEvent: false });
    this.shareForm.controls.emailText.setValue(null, { emitEvent: false });
    const keys = Object.keys(this.radioSelected);
    keys.forEach(key => {
      this.radioSelected[key] = (key === ShareMilestoneCategory.INSTALLER);
    });
  }

  onSubmit() {
    let email;
    if (this.radioSelected.INSTALLER) {
      email = this.installer?.email;
    }
    if (this.radioSelected.OTHER) {
      email = this.shareForm.controls.otherEmail.value;
    }
    if (this.radioSelected.OFFTAKER) {
      email = this.offtaker?.email;
    }

    const task = new OesTask({
      organization: this.user.organization,
      type: OesTaskType.MILESTONE,
      index: this.milestone.id,
      emailAddress: email,
      text: this.shareForm.controls.emailText.value
    });

    this._taskService.createTask(task)
      .pipe(take(1))
      .subscribe((oesTask: OesTask) => {
        this._eventService.success(this._translateService.instant('share-milestone.success-1') + ': ' + oesTask.emailAddress + ' ' + this._translateService.instant('share-milestone.success-2'));
        this.handleCloseShareMilestone();
        this._milestoneService.triggerRefetchTask();
      });
  };
}
