import { Injectable } from '@angular/core';
import { EvaluationQuestionType } from '@program/program-detail/program-configuration/shared/evaluation-config/evaluation-question-type.enum';
import { Evaluation } from '@program/shared/evaluation/evaluation.model';
import { PASS_FAIL } from '@program/shared/evaluation/pass-fail.enum';

@Injectable({
  providedIn: 'root'
})
export class CellAuditScoreService {

  constructor() { }

  renderEvaluations(evaluations: Evaluation[], orgAdmin: boolean = false) {
    if (evaluations) {
      const evalsByEditorId = evaluations.reduce((accum, evaluation) => {
        if (!accum[evaluation.editorId]) {
          accum[evaluation.editorId] = [];
        }
        accum[evaluation.editorId].push(evaluation);
        return accum;
      }, {});
      const evalIdKeys = Object.keys(evalsByEditorId);
      const totalsArray = evalIdKeys.reduce((accum, key) => {
        const relatedEvals: Evaluation[] = evalsByEditorId[key];
        const totals = this.calculateTotals(relatedEvals);
        accum.push(totals);
        return accum;
      }, []);
      return this.calculateAverages(totalsArray);
    }
    return null;
  }

  private calculateTotals(relatedEvals: Evaluation[]) {
    return relatedEvals.reduce((accum, evaluation) => {
      if (evaluation.evaluationConfig.type === EvaluationQuestionType.PASS_FAIL) {
        if (evaluation.boolValue !== null) {
          accum.totalPassFailQuestions++;
          if (evaluation.boolValue === true && accum.passFail !== PASS_FAIL.FAIL) {
            accum.passFail = PASS_FAIL.PASS;
          }
          if (evaluation.boolValue === false) {
            accum.failedPassFailQuestions++;
            accum.passFail = PASS_FAIL.FAIL;
          }
        }
      }
      if (evaluation.evaluationConfig.type === EvaluationQuestionType.SCORE) {
        if (evaluation.scoreValue !== null && evaluation.scoreValue !== undefined) {
          accum.totalScore += ((evaluation.scoreValue / evaluation.evaluationConfig.rangeEnd) * evaluation.evaluationConfig.weight);
        }
      }
      if (!accum.evaluatorName) {
        accum.evaluatorName = evaluation.editorName;
      }
      return accum;
    }, {  totalPassFailQuestions: 0, failedPassFailQuestions: 0, passFail: null, totalScore: 0, evaluatorName: '' });
  }

  private calculateAverages(totalsArray: any) {
    const averageTotals = {
      averageTotal: 0,
      passFail: null,
      totalPassFailQuestions: 0,
      totalFailedPassFailQuestions: 0
    };

    if (totalsArray.length) {
      averageTotals.averageTotal = totalsArray.reduce((accum, total) => {
        accum += (total.totalScore || 0);
        return accum;
      }, 0) / totalsArray.length;

      averageTotals.totalPassFailQuestions = totalsArray.reduce((accum, total) => {
        accum += (total.totalPassFailQuestions || 0);
        return accum;
      }, 0);

      averageTotals.totalFailedPassFailQuestions = totalsArray.reduce((accum, total) => {
        accum += (total.failedPassFailQuestions || 0);
        return accum;
      }, 0);

      averageTotals.passFail = totalsArray.reduce((accum, total) => {
        if (total.passFail === PASS_FAIL.FAIL) {
          accum = PASS_FAIL.FAIL;
        }
        if (total.passFail === PASS_FAIL.PASS && accum === null) {
          accum = PASS_FAIL.PASS;
        }
        return accum;
      }, null);
    }

    return averageTotals;
  }
}
