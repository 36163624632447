import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { environment } from '@environments/environment';
import { Formio } from 'angular-formio';
import BMF from 'browser-md5-file';
import * as FileSaver from 'file-saver';
import { isNil } from 'lodash';

import { FileUploadStorage } from '../file-upload-storage.model';
import { FORM_TYPE } from '../form-type.enum';
import url from './url-file-uploader.service';

const Components = (Formio as any).Components;
const FormioFileComponent = Components.components.file;

@Component({
  selector: 'oes-formio-file-uploader',
  templateUrl: './formio-file-uploader.component.html'
})
export class FormioFileUploaderComponent extends FormioFileComponent {
  private _httpClient: HttpClient;
  private fileNameList: string[] = [];

  constructor(@Inject('component') private component: any,
              @Inject('options') private options: any,
              @Inject('data') private data: any) {
    super(component, options, data);
    this._httpClient =  new HttpClient(new HttpXhrBackend(
      { build: () => new XMLHttpRequest() }
    ));
  }

  private findFormType(): FORM_TYPE {
    const hashArray = window.location.hash.split('/');
    if (hashArray.includes('programs') && hashArray.includes('application')) {
      return FORM_TYPE.PRE_QUALIFICATION;
    }
    if (hashArray.includes('projects') && hashArray.includes('project-application')) {
      return FORM_TYPE.PROJECT_APPLICATION;
    }
    if (hashArray.includes('portfolios') && hashArray.includes('business-plan')) {
      return FORM_TYPE.BUSINESS_PLAN;
    }
    if (hashArray.includes('portfolios') && hashArray.includes('data-room')) {
      return FORM_TYPE.CHECKLIST;
    }
  }

  upload(files) {
    if (this.data && this.data[this.component?.key] && this.data[this.component?.key].length) {
      this.fileNameList = this.data[this.component?.key].map(d => d.originalName);
    }
    const submissionId = this.evalContext().submission._id;
    const formType: FORM_TYPE = this.findFormType();

    // Only allow one upload if not multiple.
    if (!this.component.multiple) {
      if (this.statuses.length) {
        this.statuses = [];
      }
      files = Array.prototype.slice.call(files, 0, 1);
    }

    const filesLength = Object.keys(files).length;

    if (this.component.storage && files && files.length) {
      this.fileDropHidden = true;

      // files is not really an array and does not have a forEach method, so fake it.
      /* eslint-disable max-statements */
      const keys = Object.keys(files);

      keys.forEach((key, fileUploadIndex) => {
        const file = files[key];
        const bmf = new BMF();
        const hash = new Promise((resolve, reject) => {
          bmf.md5(file, (err, md5)=>{
            if (err) {
              return reject(err);
            }
            return resolve(md5);
          });
        });
        const fileName = file.name;  // File name dupes handled on backend
        const fileUpload = {
          originalName: file.name,
          name: fileName,
          size: file.size,
          status: 'info',
          progress: null,
          message: this.t('Processing file. Please wait...'),
          hash
        };

        // Check if file with the same name is being uploaded
        const fileWithSameNameUploaded = this.dataValue.some(fileStatus => fileStatus.originalName === file.name);
        const fileWithSameNameUploadedWithError = this.statuses.findIndex(fileStatus =>
          fileStatus.originalName === file.name
          && fileStatus.status === 'error'
        );

        if (fileWithSameNameUploaded) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File with the same name is already uploaded');
        }

        if (fileWithSameNameUploadedWithError !== -1) {
          this.statuses.splice(fileWithSameNameUploadedWithError, 1);
          this.redraw();
        }

        // Check file pattern
        if (this.component.filePattern && !this.validatePattern(file, this.component.filePattern)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is the wrong type; it must be {{ pattern }}', {
            pattern: this.component.filePattern,
          });
        }

        // Check file minimum size
        if (this.component.fileMinSize && !this.validateMinSize(file, this.component.fileMinSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is too small; it must be at least {{ size }}', {
            size: this.component.fileMinSize,
          });
        }

        // Check file maximum size
        if (this.component.fileMaxSize && !this.validateMaxSize(file, this.component.fileMaxSize)) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File is too big; it must be at most {{ size }}', {
            size: this.component.fileMaxSize,
          });
        }

        // Get a unique name for this file to keep file collisions from occurring.
        const dir = this.interpolate(this.component.dir || '');
        const { fileService } = this;
        if (!fileService) {
          fileUpload.status = 'error';
          fileUpload.message = this.t('File Service not provided.');
        }

        this.statuses.push(fileUpload);
        this.redraw();

        if (fileUpload.status !== 'error') {
          if (this.component.privateDownload) {
            file.private = true;
          }
          const { storage, options = {} } = this.component;
          const builtUrl = this.interpolate(this.component.url, { file: fileUpload });
          let groupKey = null;
          let groupPermissions = null;

          //Iterate through form components to find group resource if one exists
          this.root.everyComponent((element) => {
            if (element.component?.submissionAccess || element.component?.defaultPermission) {
              groupPermissions = !element.component.submissionAccess ? [
                {
                  type: element.component.defaultPermission,
                  roles: [],
                },
              ] : element.component.submissionAccess;

              groupPermissions.forEach((permission) => {
                groupKey = ['admin', 'write', 'create'].includes(permission.type) ? element.component.key : null;
              });
            }
          });

          const fileKey = this.component.fileKey || 'file';
          const groupResourceId = groupKey ? this.currentForm.submission.data[groupKey]._id : null;
          let processedFile = null;

          if (this.root.options.fileProcessor) {
            try {
              if (this.refs.fileProcessingLoader) {
                this.refs.fileProcessingLoader.style.display = 'block';
              }
              const fileProcessorHandler = super.fileProcessor(this.fileService, this.root.options.fileProcessor);
              processedFile = fileProcessorHandler(file, this.component.properties);
            }
            catch (err) {
              fileUpload.status = 'error';
              fileUpload.message = this.t('File processing has been failed.');
              this.fileDropHidden = false;
              this.redraw();
              return;
            }
            finally {
              if (this.refs.fileProcessingLoader) {
                this.refs.fileProcessingLoader.style.display = 'none';
              }
            }
          }

          fileUpload.message = this.t('Starting upload.');
          this.redraw();

          const fileUploadStorageModel: FileUploadStorage = JSON.parse(localStorage.getItem('file-upload-storage-model'));
          const hardUrl = this.getUploadUrlByUploadStorageModel(fileUploadStorageModel);
          const filePromise = this.uploadFile(
            storage,
            processedFile || file,
            fileName,
            dir,
            // Progress callback
            (evt) => {
              fileUpload.status = 'progress';
              fileUpload.progress = 100.0 * (evt.loaded / evt.total);
              delete fileUpload.message;
              this.redraw();
            },
            hardUrl,
            options,
            fileKey,
            groupPermissions,
            groupResourceId,
            // Upload start callback
            () => {
              this.emit('fileUploadingStart', filePromise);
            },
            // Abort upload callback
            (abort) => this.abortUpload = abort,
            fileUploadIndex
          ).then((fileInfo) => {
              const index = this.statuses.indexOf(fileUpload);
              if (index !== -1) {
                this.statuses.splice(index, 1);
              }
              fileInfo.originalName = fileInfo.data.name;
              fileInfo.hash = fileUpload.hash;
              if (!this.hasValue()) {
                this.dataValue = [];
              }
              this.dataValue.push(fileInfo);
              this.fileDropHidden = false;
              this.redraw();
              this.triggerChange();
              this.emit('fileUploadingEnd', filePromise);
            })
            .catch((response) => {
              fileUpload.status = 'error';
              fileUpload.message = typeof response === 'string' ? response : response.toString();
              delete fileUpload.progress;
              this.fileDropHidden = false;
              this.redraw();
              this.emit('fileUploadingEnd', filePromise);
            });
        }
      });
    }
  }

  getFinalFileName(requiredFileName: string, uploadedFileName: string, fileUploadIndex: number) {
    // NOTES
    // 1. requiredFileName is the value from the File Name Template field in the form builder.  The implementation below is intended to cover
    // the possibility that the required name may or may not have an extension.

    // 2. requiredFileName can include an embedded fileName, meaning if the user wants to pass a fileName into the string, they can do that.

    // 3. If the form requires a specific extension (via the File Pattern field in the form builder), then the uploaded file is guaranteed to
    // have the correct extension.

    // 4. If the form does not require a specific extension, then the extension from the uploaded file will pass through, but the file will be
    // renamed to match the requiredNameWithoutExtension.

    // See https://wiki.ferntech.io/en/finance/features/06_Forms/overview for details

    const uploadedNameArray = uploadedFileName.split('.');
    if (uploadedNameArray.length > 1) {
      uploadedNameArray.pop();
    }
    const uploadedNameWithoutExtension = uploadedNameArray.join('.');
    const fileNamePatterns = ['{{ fileName }}', '{{fileName}}', '{{ filename }}', '{{filename}}'];
    fileNamePatterns.forEach(pattern => {
      if (requiredFileName.includes(pattern)) {
        requiredFileName = requiredFileName.replace(pattern, uploadedNameWithoutExtension);
      }
    });
    requiredFileName = requiredFileName.replace('/', '---'); // Slashes not allowed.  Directories added elsewhere.
    const requiredNameArray = requiredFileName.split('.'); // Not actually required here.  Should be required using File Pattern field if desired.

    if (requiredNameArray.length > 1) {
      requiredNameArray.pop();
    }
    const requiredNameWithoutExtension = requiredNameArray.join('.');

    if (!uploadedFileName.includes('.')) {
      return requiredNameWithoutExtension;
    }
    const uploadedExtension = uploadedFileName.split('.')[uploadedFileName.split('.').length - 1];

    let fileNumber = '';
    if (fileUploadIndex > 0) {
      fileNumber = '(' + (fileUploadIndex + 1) + ')';
    }

    const updatedName = requiredNameWithoutExtension + fileNumber + '.' + uploadedExtension;
    const finalName = this.duplicateNameCheck(updatedName);

    return finalName;
  }

  private duplicateNameCheck(fileName: string) {
    const regexWithNumber = /\(\d+\)/;
    const lastNumberRegex = /\((\d+)\)(?=[^(]*$)/;

    if (this.fileNameList.includes(fileName) && !regexWithNumber.test(fileName)) {
      return this.duplicateNameCheck(fileName.replace(/(\.[^\.]+)$/, '(2)$1'));
    } else if (this.fileNameList.includes(fileName)) {
      const updatedFileName = fileName.replace(lastNumberRegex, (match, number) => {
        const incrementedNumber = parseInt(number, 10) + 1;
        return `(${incrementedNumber})`;
      });
      return this.duplicateNameCheck(updatedFileName);
    }
    this.fileNameList.push(fileName);
    return fileName;
  }

  uploadFile(storage, file, fileName, dir, progressCallback, urlInScope, options, fileKey, groupPermissions, groupId, uploadStartCallback, abortCallback, fileUploadIndex) {
    let finalFileName = fileName;
    if (this.component?.fileNameTemplate) {
      finalFileName = this.getFinalFileName(this.component.fileNameTemplate, fileName, fileUploadIndex);
    }

    const requestArgs = {
      provider: storage,
      method: 'upload',
      file: file,
      fileName: finalFileName,
      dir: dir
    };


    fileKey = fileKey || 'file';
    const request = Formio.pluginWait('preRequest', requestArgs)
      .then(() => {
        return Formio.pluginGet('fileRequest', requestArgs)
          .then((result) => {
            if (storage && isNil(result)) {
              const provider = url(this);
              return provider.uploadFile(file, finalFileName, dir, progressCallback, urlInScope, options, fileKey, groupPermissions, groupId, abortCallback);
            }
            return result || { url: '' };
          });
      });

    return Formio.pluginAlter('wrapFileRequestPromise', request, requestArgs);
  }

  getTaskIdFromUrl() {
    const windowUrl = window.location.href;
    const match = windowUrl.match(/task\/([a-f0-9-]{36})/i);
    return match ? match[1] : null;
  }

  getExternalToken() {
    const cookies = document.cookie.split('; ');
    for (let cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'externalToken') {
        return decodeURIComponent(value);
      }
    }
    return null;
  }

  getTokenByUserType() {
    const userType = localStorage.getItem('user-type') || 'standard'; // 'standard' or 'external'
    let token;
    if (userType === 'external') {
      token = this.getExternalToken();
    } else {
      token = sessionStorage.getItem('access_token');
    }
    return token;
  }

  getUploadUrlByUploadStorageModel(fileUploadStorageModel) {
    const userType = localStorage.getItem('user-type') || 'standard'; // 'standard' or 'external'
    let resultUrl;
    if (userType === 'external') {
      const taskId = this.getTaskIdFromUrl();
      resultUrl = `${environment.serverUri}api/1.0.0/external-tasks/${taskId}/form-documents/upload/${fileUploadStorageModel.formType}/${fileUploadStorageModel.index}`;
    } else {
      resultUrl = `${environment.serverUri}api/1.0.0/form-documents/upload/${fileUploadStorageModel.formType}/${fileUploadStorageModel.index}`;
    }
    return resultUrl;
  }

  getDeleteUrlByUserType(fileInfo) {
    const userType = localStorage.getItem('user-type') || 'standard'; // 'standard' or 'external'
    let resultUrl;
    if (userType === 'external') {
      const taskId = this.getTaskIdFromUrl();
      resultUrl = `${environment.serverUri}api/1.0.0/external-tasks/${taskId}/form-documents/delete/${fileInfo.data.id}`;
    } else {
      resultUrl = `${environment.serverUri}api/1.0.0/form-documents/delete/${fileInfo.data.id}`;
    }
    return resultUrl;
  }

  deleteFile(fileInfo) {
    const fileUploadStorageModel: FileUploadStorage = JSON.parse(localStorage.getItem('file-upload-storage-model'));
    const token = this.getTokenByUserType();
    const deleteUrl = this.getDeleteUrlByUserType(fileInfo);
    const deleteOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
      contentType: 'application/json'
    };
    this._httpClient.delete(deleteUrl, deleteOptions)
    .subscribe((response: any) => {
    });
  }

  getPrepareFileUrlByUserType(fileInfo) {
    const userType = localStorage.getItem('user-type') || 'standard'; // 'standard' or 'external'
    let fileUrl;
    if (userType === 'external') {
      const taskId = this.getTaskIdFromUrl();
      fileUrl = `${environment.serverUri}api/1.0.0/external-tasks/${taskId}/form-documents/prepare-download/${fileInfo.data.id}`;
    } else {
      fileUrl = `${environment.serverUri}api/1.0.0/form-documents/prepare-download/${fileInfo.data.id}`;
    }
    return fileUrl;
  }

  getDownloadFileUrlByUserType(fileInfo) {
    const userType = localStorage.getItem('user-type') || 'standard'; // 'standard' or 'external'
    let downloadUrl;
    if (userType === 'external') {
      const taskId = this.getTaskIdFromUrl();
      downloadUrl = `${environment.serverUri}api/1.0.0/external-tasks/${taskId}/form-documents/download`;
    } else {
      downloadUrl = `${environment.serverUri}api/1.0.0/form-documents/download`;
    }
    return downloadUrl;
  }

  getFile(fileInfo) {
    const fileUploadStorageModel: FileUploadStorage = JSON.parse(localStorage.getItem('file-upload-storage-model'));
    const token = this.getTokenByUserType();
    const prepareUrl = this.getPrepareFileUrlByUserType(fileInfo);
    const prepareOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
      contentType: 'application/json'
    };

    this._httpClient.post(prepareUrl, null, prepareOptions)
      .subscribe((download: any) => {
        const downloadUrl = this.getDownloadFileUrlByUserType(fileInfo);
        const downloadOptions = {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
          responseType: 'blob' as const,
          params: {
            documentKey: download?.downloadId
          }
        };
        this._httpClient.get(downloadUrl, downloadOptions)
        .subscribe((file: any) => {
          FileSaver.saveAs(file, fileInfo.originalName);
        });
      });
  }
}


Components.setComponent('file', FormioFileUploaderComponent);
