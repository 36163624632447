import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@shared/event.service';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EvaluationApiConstants } from './evaluation.constant';
import { Evaluation } from './evaluation.model';
import { EntityType } from '@program/program-detail/program-configuration/shared/evaluation-config/entity-type.enum';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService,
              private _eventService: EventService,
              private _translateService: TranslateService) {
    super(_restApiWrapperService);
  }

  detail(evaluationId: string): Observable<Evaluation> {
    return this.get<Evaluation>(EvaluationApiConstants.evaluation.detail(evaluationId))
      .pipe(
        map((response: Evaluation) => {
          if (response) {
            return new Evaluation(response);
          }
          return null;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  create(evaluation: Evaluation) {
    return this.post(EvaluationApiConstants.evaluation.create(), JSON.stringify(evaluation))
      .pipe(
        map((response: Evaluation) => {
          return new Evaluation(response);
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  buildFormRequest(evaluatedEntityId: string, entityType: EntityType) {
    let params: HttpParams = new HttpParams();
    params = params.set('evaluatedEntityId', evaluatedEntityId);
    params = params.set('entityType', entityType);
    return this.list(params);
  }

  list(params: any): Observable<Evaluation[]> {
    return this.get<Evaluation[]>(EvaluationApiConstants.evaluation.find(), { params: params })
      .pipe(
        map((response: Evaluation[]) => {
          return response.map(evaluation => new Evaluation(evaluation));
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  listByConfiguredEntityId(configuredEntityId: string): Observable<Evaluation[]> {
    return this.get<Evaluation[]>(EvaluationApiConstants.evaluation.findByConfiguredEntityId(configuredEntityId))
      .pipe(
        map((response: Evaluation[]) => {
          return response.map(evaluation => new Evaluation(evaluation));
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  // Requires ORGANIZATION_ADMIN role
  adminListByConfiguredEntityId(configuredEntityId: string): Observable<Evaluation[]> {
    return this.get<Evaluation[]>(EvaluationApiConstants.evaluation.adminFindByConfiguredEntityId(configuredEntityId))
      .pipe(
        map((response: Evaluation[]) => {
          return response.map(evaluation => new Evaluation(evaluation));
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  // Requires ORGANIZATION_ADMIN role
  adminListByEvaluatedEntityId(evaluatedEntityId: string, entityType: EntityType): Observable<Evaluation[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('entityType', entityType);
    return this.get<Evaluation[]>(EvaluationApiConstants.evaluation.adminFindByEvaluatedEntityId(evaluatedEntityId), { params: params })
      .pipe(
        map((response: Evaluation[]) => {
          return response.map(evaluation => new Evaluation(evaluation));
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  update(evaluation: Evaluation): Observable<Evaluation> {
    return this.put(EvaluationApiConstants.evaluation.update(evaluation.id), JSON.stringify(evaluation))
      .pipe(
        map((response: Evaluation) => {
          return new Evaluation(response);
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  remove(evaluationId: string): Observable<any> {
    return this.delete(EvaluationApiConstants.evaluation.delete(evaluationId))
      .pipe(
        map((response: Evaluation[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        })
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 504 || error.status === 0) {
      this._eventService.error(this._translateService.instant('program.configuration.evaluation.server-error'));
      return EMPTY;
    }
    return throwError(error);
  }
}
