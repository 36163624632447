import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MilestoneConfig } from '@program/shared/milestone-config.model';
import { DynamicFormService } from '@shared/components/dynamic-form/dynamic-form.service';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MILESTONE_TYPE } from './milestone-type.enum';
import { Milestone } from './milestone.model';
import { ProjectMilestonesConstant } from './project-milestones.constant';

@Injectable({
  providedIn: 'root'
})
export class ProjectMilestonesService extends BaseRestApiService {

  constructor(private _dynamicFormService: DynamicFormService,
              _restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  findAllByProjectId(projectId: string, type: MILESTONE_TYPE): Observable<Milestone[]> {
    let params = new HttpParams();
    params = params.set('size', 1000);
    params = params.set('milestoneType', type);
    const options = { params: params };

    return this.get<Milestone[]>(ProjectMilestonesConstant.milestoneConfig.findAllByProjectId(projectId), options)
    .pipe(map(milestone => milestone.sort((a, b) => {
      return a.milestoneConfig.index - b.milestoneConfig.index;
    }).map(m => ({
      ...m,
      name: m.name ? m.name : m.milestoneConfig.name,
      description: m.description ? m.description : m.milestoneConfig.description
    }))));
  }

  getById(projectId: string, milestoneId: string): Observable<Milestone> {
    return this.get<Milestone>(ProjectMilestonesConstant.milestoneConfig.getById(projectId, milestoneId));
  }

  update(projectId: string, milestoneId: string, body: any): Observable<Milestone> {
    return this.put<Milestone>(ProjectMilestonesConstant.milestoneConfig.update(projectId, milestoneId), body);
  }

  updateAll(projectId: string, body: Milestone[], type: MILESTONE_TYPE): Observable<Milestone[]> {
    const options = {
      params: { type: type }
    };
    return this.put<Milestone[]>(ProjectMilestonesConstant.milestoneConfig.updateAll(projectId), body, options);
  }

  deleteById(projectId: string, milestoneId: string): Observable<any> {
    return this.delete<any>(ProjectMilestonesConstant.milestoneConfig.delete(projectId, milestoneId));
  }

  createDeveloperMilestone(projectId: string, body: Milestone): Observable<Milestone> {
    return this.post<Milestone>(ProjectMilestonesConstant.milestoneConfig.create(projectId), body);
  }

  updateAllConfigs(projectId: string, body: any): Observable<MilestoneConfig[]> {
    return this.put<MilestoneConfig[]>(ProjectMilestonesConstant.milestoneConfig.updateAllConfigs(projectId), body);
  }

  getForms(projectId: string): Observable<any> {
    return this.get<any>(ProjectMilestonesConstant.forms.getForms(projectId));
  }

  uploadMilestoneForm(projectId: string, configId: string, fileName: string, json: {}): Observable<any> {
    let jsonObject = this._dynamicFormService.removeBlankNodes({...json});
    // add Validate button before Submit button
    jsonObject = this._dynamicFormService.checkValidateButton(jsonObject);
    // add submit button
    jsonObject = this._dynamicFormService.checkSubmitButton(jsonObject);
    // add save button to all forms
    jsonObject = this._dynamicFormService.checkSaveButton(jsonObject);
    jsonObject = this._dynamicFormService.saveOriginalFileName(jsonObject, fileName);
    const file = new File([JSON.stringify(jsonObject)], fileName, {type: 'text/json'});
    const formData = new FormData();
    formData.append('file', file);
    return this.put(ProjectMilestonesConstant.forms.create(projectId, configId), formData);
  }

  deleteMilestoneForm(projectId: string, configId: string): Observable<any> {
    return this.delete(ProjectMilestonesConstant.forms.delete(projectId, configId));
  }

  saveFormData(projectId, milestoneId: string, json: {}): Observable<any> {
    let jsonObject: any = this._dynamicFormService.removeBlankNodes({...json});
    // add Validate button before Submit button
    jsonObject = this._dynamicFormService.checkValidateButton(jsonObject);
    // add save button to all forms
    jsonObject = this._dynamicFormService.checkSaveButton(jsonObject);
    const invalidComponents = this._dynamicFormService.validate(jsonObject.components, jsonObject.data);
    const valid = invalidComponents.length === 0;
    const options = { params: new HttpParams().set('formComplete', valid.toString()) };
    const file = new File([JSON.stringify(jsonObject)], 'milestone-form', {type: 'text/json'});
    const formData = new FormData();
    formData.append('file', file);
    return this.post(ProjectMilestonesConstant.forms.saveFormData(projectId, milestoneId), formData, options);
  }

  markExternalTaskCompleted(projectId, milestoneId): Observable<any> {
    return this.put(ProjectMilestonesConstant.forms.markExternalTaskCompleted(projectId, milestoneId));
  }
}
