<oes-modal-dialog
  #shareMilestoneModal
  id="shareMilestoneModal"
  [size]="'small'"
  [allowCancel]="true"
  [allowSubmit]="true"
  (submit)="onSubmit()"
  [submitButtonText]="'buttons.share' | translate"
  [title]="'share-milestone.share-externally' | translate"
  [hideCloseBtn]="true"
  (close)="handleCloseShareMilestone()">
  <ng-container *ngIf="modalShowing">
    <div class="milestone-share">
      <h5>{{ 'share-milestone.share-with' | translate }}</h5>
      <form [formGroup]="shareForm" class="share-form">
        <div class="radio-inputs">

          <div *ngIf="installer" class="radio-input">
            <input class="input-control radio-fresh" type="radio" value="INSTALLER" name="category" formControlName="category" />
            <label for="category">
              <p>{{ installer?.name }} ({{ 'share-milestone.category.installer' | translate }})</p>
              <p>{{ installer?.email }}</p>
            </label>
          </div>

          <div *ngIf="offtaker" class="radio-input">
            <input class="input-control radio-fresh" type="radio" value="OFFTAKER" name="category" formControlName="category" />
            <label for="category">
              <p>{{ offtaker?.name }} ({{ 'share-milestone.category.offtaker' | translate }})</p>
              <p>{{ offtaker?.email }}</p>
            </label>
          </div>

          <div class="radio-input">
            <input class="input-control radio-fresh" type="radio" value="OTHER" name="category" formControlName="category" />
            <label for="category">
              <p>{{ 'share-milestone.category.other' | translate }}</p>
              <p>{{ 'share-milestone.email' | translate }}</p>
            </label>
          </div>

        </div>

        <div class="other-email-input" *ngIf="radioSelected?.OTHER">
          <label for="otherEmail" class="label">{{ 'share-milestone.email-label' | translate }}</label>
          <input
            type="text"
            class="mb-2"
            id="otherEmail"
            [placeholder]="'share-milestone.email-placeholder' | translate"
            formControlName="otherEmail" />
        </div>

        <div class="email-text-input">
          <label for="emailText" class="label">{{ 'share-milestone.email-text' | translate }}</label>
          <textarea
            class="mb-2"
            id="emailText"
            maxlength="500"
            [placeholder]="'share-milestone.email-text-placeholder' | translate"
            formControlName="emailText">
          </textarea>
        </div>

      </form>
    </div>
  </ng-container>
</oes-modal-dialog>
