import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModulePermissions } from '@project/shared/module-permissions.model';
import { Project } from '@project/shared/project.model';
import { DOCUMENT_TAG_TYPE } from '@shared/components/files/shared/document-tag-type.enum';
import { TagType } from '@shared/components/files/shared/models/tag-type.model';
import { ProjectResolve } from '../project-resolve.model';
import { MILESTONE_CONTENT_TYPE, MILESTONE_TYPE } from './milestone-type.enum';
import { switchMap, take, takeUntil, catchError } from 'rxjs/operators';
import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { MilestoneService } from './milestone.service';
import { Milestone } from './milestone.model';
import { MilestoneConfigEvent, MilestoneFilesWrapper, MilestoneUpdateEvent } from './milestone-util-types';
import { ProjectMilestonesService } from './project-milestones.service';
import { DocumentDataService } from '@shared/components/files/shared/document-data.service';
import { FileNameCheckerService } from '@shared/services/file-name-checker.service';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { MILESTONE_STATUS } from '@program/shared/milestone-status.enum';
import { MilestoneConfig } from '@program/shared/milestone-config.model';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '@shared/event.service';
import { FilesActions } from '@shared/components/files/shared/models/action.model';
import { OfftakerService } from '@project/offtakers/offtaker.service';
import { Offtaker } from '@project/offtakers/offtaker.model';
import { InstallerService } from '@project/installers/installer.service';
import { Installer } from '@project/installers/installer.model';

@Component({
  selector: 'oes-project-milestones',
  templateUrl: './project-milestones.component.html',
  styleUrls: ['./project-milestones.component.scss'],
})
export class ProjectMilestonesComponent implements OnInit, OnDestroy {
  @Input() emptyListContent: TemplateRef<any>;
  @Input() showCount = true;
  @Input() showListExport = true;
  @Input() showMilestonesInOneList = false;
  @Input() project: Project; // Optional

  currentUser: User;
  milestoneTypes = MILESTONE_TYPE;
  readOnly: boolean;
  isLoading: boolean = true;
  isLoadingModal: boolean = false;
  isProgramOwner: boolean;
  message = {};
  tagTypes: TagType[];
  programMilestones: Milestone[] = [];
  projectMilestones: Milestone[] = [];
  projectInstaller: Installer;
  projectOfftaker: Offtaker;

  private modulePermissions: ModulePermissions;
  private deleteMilestoneSubscription: Subscription;
  private submitUpdateSubscription: Subscription;
  private submitCreateConfigSubscription: Subscription;
  private submitUpdateConfigSubscription: Subscription;
  private refetchMilestonesSubscription: Subscription;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private _activatedRoute: ActivatedRoute,
              private _documentDataService: DocumentDataService,
              private _eventService: EventService,
              private _fileNameCheckerService: FileNameCheckerService,
              private _milestoneService: MilestoneService,
              private _projectMilestonesService: ProjectMilestonesService,
              private _translateService: TranslateService,
              private _offtakerService: OfftakerService,
              private _installerService: InstallerService,
              private _userService: UserService) {
  }

  ngOnInit(): void {
    this._activatedRoute.parent.data
    .pipe(take(1))
    .subscribe((data: {result: ProjectResolve}) => {
      if (this.project === undefined) {
        this.project = data?.result?.project;
      }
      this.getProjectOfftaker();
      this.getProjectInstaller();
      this.modulePermissions = this.project?.projectPermissions?.project?.modulePermissions;
      this.readOnly = this.modulePermissions?.milestones?.readOnly;
      this.isProgramOwner = this.project?.projectPermissions?.currentUser?.programOwner;

      this.setTagTypes();
    });
    this._userService.getCurrentUser()
    .pipe(take(1))
    .subscribe((user: User) => {
      this.currentUser = user;
    });
    this.message = this._translateService.instant('milestones.message');
    this.getMilestones([MILESTONE_TYPE.PROGRAM, MILESTONE_TYPE.PROJECT], true);
    this.submitUpdateSubscription = this._milestoneService.submitUpdate$.subscribe(
      (milestoneUpdateEvent) => milestoneUpdateEvent ? this.onMilestoneUpdate(milestoneUpdateEvent) : null
    );
    this.submitCreateConfigSubscription = this._milestoneService.submitCreateConfig$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((milestoneConfigEvent) => {
        return milestoneConfigEvent ? this.onMilestoneCreate(milestoneConfigEvent) : null;
      }
    );
    this.submitUpdateConfigSubscription = this._milestoneService.submitUpdateConfig$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((milestoneConfigEvent) => {
        return milestoneConfigEvent ? this.onConfigUpdate(milestoneConfigEvent) : null;
      }
    );
    this.deleteMilestoneSubscription = this._milestoneService.deleteMilestone$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((milestone) => {
        return milestone ? this.onMilestoneDelete(milestone) : null;
      }
    );
    this.refetchMilestonesSubscription = this._milestoneService.refetchMilestones$.subscribe(() => {
      this.getMilestones([MILESTONE_TYPE.PROGRAM, MILESTONE_TYPE.PROJECT], true);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    if (this.deleteMilestoneSubscription) {
      this.deleteMilestoneSubscription.unsubscribe();
    }
    if (this.submitUpdateSubscription) {
      this.submitUpdateSubscription.unsubscribe();
    }
    if (this.submitCreateConfigSubscription) {
      this.submitCreateConfigSubscription.unsubscribe();
    }
    if (this.submitUpdateConfigSubscription) {
      this.submitUpdateConfigSubscription.unsubscribe();
    }
    if (this.refetchMilestonesSubscription) {
      this.refetchMilestonesSubscription.unsubscribe();
    }
  }

  private getProjectOfftaker() {
    if (this.project) {
      const params = { page: 0, size: 1000, projectId: this.project.id };
      this._offtakerService.list(params)
        .pipe(take(1))
        .subscribe((offtakers: Offtaker[]) => {
          if (offtakers.length > 0) {
            this.projectOfftaker = offtakers[0];
          }
        });
    }
  }

  private getProjectInstaller() {
    if (this.project) {
      const params = { page: 0, size: 1000, projectId: this.project.id };
      this._installerService.list(params)
        .pipe(take(1))
        .subscribe((installers: Installer[]) => {
          if (installers.length > 0) {
            this.projectInstaller = installers[0];
          }
        });
    }
  }

  addDocuments = (file: File, milestone: Milestone, noteId?: string): Observable<any> => {
    const s3SafeName = this._fileNameCheckerService.s3Safe(file.name);
    const project: Project = milestone.project;
    const formData = new FormData();

    formData.append('file', file, s3SafeName);
    formData.append('index', milestone?.id);
    formData.append('systemDocumentType', DOCUMENT_TAG_TYPE.MILESTONE);
    formData.append('docTagItemId', milestone?.id);
    formData.append('docTagType', DOCUMENT_TAG_TYPE.MILESTONE);
    formData.append('docTagItemId', project.id);
    formData.append('docTagType', DOCUMENT_TAG_TYPE.PROJECT);
    if (noteId) {
      formData.append('docTagType', DOCUMENT_TAG_TYPE.MILESTONE_NOTE);
      formData.append('docTagItemId', noteId);
    }
    formData.append('uploadPath', '');
    return this._documentDataService.uploadMilestoneDocuments(formData, this.currentUser.organization.id);
  };

  private submitMilestoneThenDocs = (
    milestone: Milestone,
    filesAdded: MilestoneFilesWrapper,
    milestoneType: MILESTONE_TYPE
  ) => {
    // If note is present but without ID, it's documents being added to a new note.
    // We have to first create the note and acquire its ID, in order to supply it to document tags.
    this._projectMilestonesService.update(milestone.project.id, milestone.id, milestone)
      .pipe(
        switchMap(response => {
          const oldNoteIds = milestone.notes.map(note => note.id);
          const noteId = response.notes.find(note => !oldNoteIds.includes(note.id))?.id;
          const fileRequests = filesAdded.files.map(
            file => this.addDocuments(file, milestone, noteId)
          );
          return forkJoin(fileRequests);
        })
      ).subscribe(_ => {
        this._eventService.success(this.message['update-success']);
        this.getMilestones([milestoneType]);
      },
      error => {
        this._eventService.error(this.message['note-create-error']);
        console.error(error);
      });
  };

  private findDeletedDocKeys = (milestone: Milestone): any[] => {
    const targetMilestone = this.programMilestones.concat(this.projectMilestones)
      .find(m => m.id === milestone.id);
    // Checking if documents were deleted
    if (targetMilestone.documents.length > milestone.documents.length) {
      const docIds = milestone.documents.map(doc => doc.id);
      return targetMilestone.documents
        .filter(doc => !docIds.includes(doc.id))
        .map(doc => ({ docType: 'FILE', key: doc.documentKey.key }));
    }
    return [];
  };

  private postProcessMilestone = (milestone: Milestone): Milestone => {
    const currentDate = new Date();
    return {
      ...milestone,
      completedDate: milestone.status === MILESTONE_STATUS.COMPLETED ? currentDate : null,
      updated: currentDate
    };
  };

  onMilestoneUpdate = ({ milestone, filesAdded, milestoneType }: MilestoneUpdateEvent) => {
    this.isLoading = true;
    milestone = this.postProcessMilestone(milestone);
    const requests: Observable<any>[] = [];
    let milestoneUpdated = false;

    if (filesAdded.files?.length) {
      if (filesAdded.note !== undefined && filesAdded.note !== null && filesAdded.note.id === undefined) {
        this.submitMilestoneThenDocs(milestone, filesAdded, milestoneType);
        milestoneUpdated = true;
      } else {
        filesAdded.files.forEach(file => {
          requests.push(this.addDocuments(file, milestone, filesAdded.note?.id));
        }
        );
      }
    }
    const deletedDocumentKeys = this.findDeletedDocKeys(milestone);
    if (deletedDocumentKeys.length) {
      requests.push(this._documentDataService.deleteDocuments(deletedDocumentKeys));
    }
    if (!milestoneUpdated) {
      requests.push(this._projectMilestonesService.update(milestone.project.id, milestone.id, milestone));
    }
    forkJoin(requests)
      .pipe(take(1))
      .subscribe((_) => {
        this._eventService.success(this.message['update-success']);
        this.getMilestones([milestoneType]);
      });
  };

  onMilestoneCreate = ({ data, milestoneType }: MilestoneConfigEvent) => {
    const index = milestoneType === MILESTONE_TYPE.PROGRAM
      ? this.programMilestones.length
      : this.projectMilestones.length;
    const milestoneConfig = new MilestoneConfig({
      name: data.name,
      description: data.description,
      project: this.project,
      ownerOrganization: this.currentUser.organization,
      notificationUsers: data.notificationUsers,
      notificationBaseUrl: data.notificationUsers?.length ? window.location.origin : null,
      index: index,
      contentType: data.form ? MILESTONE_CONTENT_TYPE.FORM : MILESTONE_CONTENT_TYPE.NOTE
    });
    const milestone = new Milestone({
      project: this.project,
      milestoneConfig: milestoneConfig,
      status: MILESTONE_STATUS.NOT_STARTED
    });
    this._projectMilestonesService.createDeveloperMilestone(this.project.id, milestone)
    .pipe(take(1))
    .subscribe((devMilestone: Milestone) => {
      this._eventService.success(this.message['create-success']);
      if (data.form) {
        this.saveMilestoneForm(devMilestone.milestoneConfig.id, data.form)
        .pipe(take(1))
        .subscribe(_ => {
          this._eventService.success(this.message['form-uploaded']);
          this.getMilestones([milestoneType], true);
        });
      } else {
        this.getMilestones([milestoneType], false);
      }
    });
  };

  saveMilestoneForm(configId: string, form: File): Observable<any> {
    return new Observable<any>((observer) => {
      const reader = new FileReader();
      reader.onload = (ev) => {
        try {
          const jsonObject = JSON.parse(ev.target.result as string);
          const requestObservable = this._projectMilestonesService.uploadMilestoneForm(
            this.project.id, configId, form.name, jsonObject);
          requestObservable.subscribe({
            next: (res) => observer.next(res),
            error: (err) => observer.error(err),
            complete: () => observer.complete()
          });
        } catch (e) {
          this._eventService.error(this.message['form-upload-error']);
          observer.error(e);
        }
      };
      reader.onerror = (e) => {
        observer.error(e);
      };
      reader.readAsText(form);
    });
  }

  onConfigUpdate = ({ data, milestone, milestoneType }: MilestoneConfigEvent) => {
    milestone.milestoneConfig.notificationUsers = data.notificationUsers;
    if (data?.notificationUsers?.length) {
      milestone.milestoneConfig.notificationBaseUrl = window.location.origin;
    }
    milestone.name = data.name ? data.name : milestone.milestoneConfig.name;
    const oldMilestone = this.programMilestones.concat(this.projectMilestones).find(m => m.id === milestone.id);
    let contentType = oldMilestone.milestoneConfig.contentType;
    let formAdded = false;
    let formRemoved = false;
    if ((data.form && contentType !== MILESTONE_CONTENT_TYPE.FORM) || (data.form && data.form instanceof File)) {
      contentType = MILESTONE_CONTENT_TYPE.FORM;
      formAdded = true;
    } else if (data.form === undefined && contentType === MILESTONE_CONTENT_TYPE.FORM) {
      contentType = MILESTONE_CONTENT_TYPE.NOTE;
      formRemoved = true;
    }

    this._projectMilestonesService.update(milestone.project.id, milestone.id, {
      ...milestone,
      name: data.name,
      description: data.description,
      notes: undefined,
      milestoneConfig: {
        ...milestone.milestoneConfig,
        contentType: contentType
      }
    })
    .pipe(take(1))
    .subscribe((milestoneUpdated) => {
      this._eventService.success(this.message['update-success']);
      if (formAdded) {
        this.saveMilestoneForm(milestoneUpdated.milestoneConfig.id, data.form)
        .pipe(take(1))
        .subscribe(_ => {
          this._eventService.success(this.message['form-uploaded']);
          this.getMilestones([milestoneType], true);
        });
      } else if (formRemoved) {
        this._projectMilestonesService.deleteMilestoneForm(this.project.id, milestoneUpdated.milestoneConfig.id)
        .pipe(take(1))
        .subscribe(_ => {
          this._eventService.success(this.message['form-removed']);
          this.getMilestones([milestoneType], true);
        });
      }

      this.getMilestones([milestoneType], false);
    });
  };

  onMilestoneDelete = (milestone: Milestone) => {
    const milestoneType = milestone.milestoneConfig.program
      ? MILESTONE_TYPE.PROGRAM
      : MILESTONE_TYPE.PROJECT;
    this._projectMilestonesService.deleteById(this.project.id, milestone.id)
    .pipe(take(1))
    .subscribe(_ => {
      this._eventService.success(this.message['delete-success']);
      this.getMilestones([milestoneType]);
    });
  };

  setIsLoadingModal(isLoadingModal: boolean) {
    this.isLoadingModal = isLoadingModal;
  }

  private getMilestones(milestoneTypes: MILESTONE_TYPE[], shouldGetForms: boolean = false) {
    const requests: { [key: string]: Observable<any> } = {};

    // Map each milestone type to its corresponding Observable
    milestoneTypes.forEach(milestoneType => {
      requests[milestoneType] = this._projectMilestonesService
        .findAllByProjectId(this.project.id, milestoneType)
        .pipe(
          catchError(error => {
            this._eventService.error(`${this.message['milestones-load-error']}: ${error}`);
            return of(null);
          })
        );
    });

    if (shouldGetForms) {
      requests['FORMS'] = this._projectMilestonesService
        .getForms(this.project.id)
        .pipe(
          catchError(error => {
            this._eventService.error(`${this.message['forms-load-error']}: ${error}`);
            return of(null);
          })
        );
    }

    forkJoin(requests).subscribe({
      next: results => {
        if (results[MILESTONE_TYPE.PROGRAM]) {
          const programMilestones = results[MILESTONE_TYPE.PROGRAM];
          this._milestoneService.setMilestoneData(programMilestones, MILESTONE_TYPE.PROGRAM);
          this.programMilestones = JSON.parse(JSON.stringify(programMilestones));
        }

        if (results[MILESTONE_TYPE.PROJECT]) {
          const projectMilestones = results[MILESTONE_TYPE.PROJECT];
          this._milestoneService.setMilestoneData(projectMilestones, MILESTONE_TYPE.PROJECT);
          this.projectMilestones = JSON.parse(JSON.stringify(projectMilestones));
        }

        if (results['FORMS']) {
          this._milestoneService.setAllForms(results['FORMS']);
        }

        this.isLoading = false;
        this.isLoadingModal = false;
      },
      error: err => {this._eventService.error(`${this.message['load-error']}: ${err}`);
      }
    });
  }

  private setTagTypes() {
    this.tagTypes = [{
      docTagItemId: this.project.id,
      docTagType: DOCUMENT_TAG_TYPE.MILESTONE,
      name: (DOCUMENT_TAG_TYPE.MILESTONE).replace('_', ' ').toLowerCase()
    }, {
      docTagItemId: this.project.id,
      docTagType: DOCUMENT_TAG_TYPE.PROJECT,
      name: this.project.name
    }];
  }

  get accessControl (): any {
    if (!this.readOnly) {
      return new FilesActions({
        copy: true,
        create: true,
        delete: true,
        download: true,
        move: true,
        upload: true,
        tag: true,
        editName: true,
        preview: true
      });
    } else {
      return new FilesActions({
        download: true,
        preview: true
      });
    }
  }

}
