import { Injectable } from '@angular/core';
import { BaseRestApiService } from '@shared/services/base-rest-api.service';
import { RestApiWrapperService } from '@shared/services/rest-api-wrapper.service';
import { OesTask } from './task.model';
import { TaskApiConstants } from './task.constant';
import { OesTaskType } from './task-type.enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseRestApiService {

  constructor(_restApiWrapperService: RestApiWrapperService) {
    super(_restApiWrapperService);
  }

  createTask(task: OesTask): Observable<OesTask> {
    return this.post<OesTask>(TaskApiConstants.task.create(), JSON.stringify(task));
  }

  findByTypeAndIndex(type: OesTaskType, index: string): Observable<OesTask[]> {
    const params = { page: 0, size: 1000, type: type, index: index };
    return this.get<OesTask[]>(TaskApiConstants.task.find(), { params: params });
  }
}
