import { Component } from '@angular/core';

@Component({
  selector: 'oes-completed-task',
  templateUrl: 'completed-task.component.html',
  styleUrls: ['completed-task.component.scss']
})

export class CompletedTaskComponent {

  constructor() {}

}
