import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { User } from '@user/user.model';
import { UserService } from '@user/user.service';
import { Subscription, take } from 'rxjs';

import { MILESTONE_TYPE } from '../milestone-type.enum';
import { MilestoneConfigAction, MilestoneConfigInputs, MilestoneConfigRaw } from '../milestone-util-types';
import { Milestone } from '../milestone.model';
import { MilestoneService } from '../milestone.service';

@Component({
  selector: 'oes-milestone-config',
  templateUrl: './milestone-config.component.html',
  styleUrls: ['./milestone-config.component.scss'],
})
export class MilestoneConfigComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('milestoneConfigModal', {static: false}) milestoneConfigModal: ModalDialogComponent;
  @ViewChild('deleteWarningModal', {static: false}) deleteWarningModal: ModalDialogComponent;
  @ViewChild('configForm', {static: false}) configForm: any;

  @Output() deleteMilestone: EventEmitter<Milestone> = new EventEmitter<Milestone>();
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  action: MilestoneConfigAction;
  formName: string;
  milestoneConfig: MilestoneConfigRaw;
  milestoneInput: Milestone;
  milestoneNotificationUsers: User[] = [];
  milestoneNotificationUsersOptions: User[];
  milestoneType: MILESTONE_TYPE;
  milestoneConfigModalShowing: boolean = false;
  submitAttempted: boolean = false;

  private openMilestoneConfigSubscription: Subscription;

  constructor(private _milestoneService: MilestoneService,
              private _userService: UserService) {};

  ngOnInit() {
    const orgId = this._userService.organizationId;
    this._userService.getUsersByOrganizationId(orgId)
      .pipe(take(1))
      .subscribe((users: User[]) => {
        this.milestoneNotificationUsersOptions = users;

        this.openMilestoneConfigSubscription = this._milestoneService.openMilestoneConfig$
        .subscribe((data: MilestoneConfigInputs) => {
          if (!data) {
            return;
          }
          this.action = data.action;
          this.milestoneInput = data.milestoneInput;
          this.milestoneType = data.milestoneType;
          this.milestoneNotificationUsers = this.milestoneInput?.milestoneConfig?.notificationUsers || [];
          this.milestoneConfig = {
            name: this.milestoneInput?.name || this.milestoneInput?.milestoneConfig?.name || '',
            description: this.milestoneInput?.description || this.milestoneInput?.milestoneConfig?.description || '',
            form: data.form,
            notificationUsers: this.milestoneNotificationUsers || []
          };
          this.setDisplayFormName();
          this.milestoneConfigModalShowing = true;
          this.milestoneConfigModal.show();
        });
      });
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.milestoneConfig?.currentValue && this.milestoneConfig.form) {
      this.setDisplayFormName();
    }
  }

  ngOnDestroy(): void {
    if (this.openMilestoneConfigSubscription) {
      this.openMilestoneConfigSubscription.unsubscribe();
    }
  }

  setSelectedUsers(users: User[]) {
    this.milestoneNotificationUsers = users;
  }

  onSubmit() {
    if (this.configForm.valid) {
      let data: any = {};
      if (!this.milestoneInput) {
        // if creating a new milestone
        data = this.milestoneConfig;
        data.notificationUsers = this.milestoneNotificationUsers;
        if (data.notificationUsers?.length) {
          data.notificationBaseUrl = window.location.origin;
        }
      } else {
        // if updating existing milestone config
        // Avoiding needless name & description overwrites
        if (this.milestoneConfig.name !== this.milestoneInput.name) {
          data.name = this.milestoneConfig.name;
        }
        if (this.milestoneConfig.description !== this.milestoneInput.description) {
          data.description = this.milestoneConfig.description;
        }
        data.notificationUsers = this.milestoneNotificationUsers;
        if (data.notificationUsers?.length) {
          data.notificationBaseUrl = window.location.origin;
        }
        data.form = this.milestoneConfig.form;
      }
      this._milestoneService.configMilestone({
        data,
        milestone: this.milestoneInput,
        milestoneType: this.milestoneType,
      }, this.action);
      this.isLoading.emit(true);
      this.milestoneConfigModal.hide();
      this.milestoneConfigModalShowing = false;
      this.submitAttempted = false;
      this.configForm.reset();
    } else {
      this.submitAttempted = true;
    }
  };

  setDisplayFormName() {
    if (!this.milestoneConfig.form) {
      return;
    }
    this.formName = this.milestoneConfig.form.originalName
      ? this.milestoneConfig.form.originalName
      : JSON.parse(this.milestoneConfig.form)['originalName'];
  }

  uploadFormClick(event: MouseEvent) {
    event.preventDefault();
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = _ => {
      if (input.files.length) {
        this.milestoneConfig.form = input.files[0];
      }
    };
    input.click();
  }

  removeFormClick(event: MouseEvent) {
    event.preventDefault();
    this.milestoneConfig.form = undefined;
  }

  onDelete() {
    this._milestoneService.deleteMilestone(this.milestoneInput);
    this.deleteWarningModal.hide();
    this.milestoneConfigModal.hide();
    this.milestoneConfigModalShowing = false;
  };

  handleOpen() {
    this.milestoneConfigModalShowing = true;
    this.milestoneConfigModal.show();
  };

  handleOpenDeleteWarning() {
    this.deleteWarningModal.show();
  };
};
