import { MainMenuCategory } from '../../main-menu-category.enum';


export class CrmNav {
  public static item = {
    title: 'main-navigation.crm',
    url: '/oes/crm/home',
    testId: 'crm-nav-link',
    icon: '<img src="./assets/icons/line-icons/nav/colors/crm.svg" width="24px">',
    bold: true,
    type: MainMenuCategory.crm,
  };
}
