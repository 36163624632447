import { Injectable } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class FormatterService {
  constructor() {
  }

  public formatPercent(params: any, locale: string): string {
    if (params && !Number.isNaN(params.value)) {
      return new PercentPipe(locale).transform(params.value, '1.0-2');
    }
    // valueFormatter needs a return value
    return '';
  }

  // Used for formatting when a data is returned by the server, e.g. '2020-01-19'
  public formatDate(params: any) {
    if (params.value) {
      // format date string from how it is stored in the db ('yyyy-mm-dd') to 'MM/dd/yyyy'
      return DateTime.fromISO(params.value).toFormat('yyyy-MM-dd');
    }
    return null;
  }

  public formatDateFromDateObject(params: any) {
    if (params.value) {
      return DateTime.fromFormat(params.value, 'MM/dd/yyyy').toFormat('MM/dd/yyyy');
    }
    return null;
  }

  public dateComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
    const dateAsString = cellValue.substring(0, 10);
    if (dateAsString == null) {
      return -1;
    }
    const dateParts = dateAsString.split('-');
    // adjusted the second param for customer connection.
    // Number(dateParts[1]) -> Number(dateParts[1] - 1)
    const cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  }

  public getFormatProjectTypeFn(projectTypeTranslations: any) {
    return (params: any) => {
      return this.formatProjectType(params, projectTypeTranslations);
    };
  }

  private formatProjectType(params: any, projectTypeTranslations: any) {
    if (params.value && params.value.trim() !== '') {
      if (params.value === 'MINIGRID') {
        return projectTypeTranslations['minigrid'];
      }
      if (params.value === 'SOLAR_HOME_SYSTEM') {
        return projectTypeTranslations['solar-home-system'];
      }
      if (params.value === 'MARKET') {
        return projectTypeTranslations['market'];
      }
    }
  }

  public formatCurrency(params: any, currentLang: string): string {
    if (params && !Number.isNaN(params.value)) {
      return new CurrencyPipe(currentLang).transform(params.value, 'USD', 'symbol', '1.2-2');
    }
    return '';
  }

  // To use program
  public getLocalDate(params: any, format: string, currentLang: string) {
    if (params.value) {
      return DateTime.fromISO(params.value, {zone: 'utc'})  // load as utc
                    .setLocale(currentLang)                // set lang for translation
                    .setZone(DateTime.local().zoneName)    // set timezone
                    .toFormat(format);
    }
    return '-';
  }
}
