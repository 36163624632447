import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TaskRouteGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const hash = window.location.hash;
    let token;
    token = this.getQueryParamFromHash(hash, 'token');
    if (token) {
      if (this.isTokenValid(token)) {
        this._router.navigate(['/task', this.getTaskIdFromHash(hash)]);
        this.saveTokenAsCookie(token);
        return true;
      } else {
        this._router.navigate(['../../oes/home']);
        return false;
      }
    } else {
      token = this.getTokenFromCookies();
      if (token && this.isTokenValid(token)) {
        return true;
      }
    }

    this._router.navigate(['../../oes/home']);
    return false;
  }

  private getTaskIdFromHash(hash: string): string | null {
    const match = hash.match(/\/task\/([a-zA-Z0-9-]+)/);
    return match ? match[1] : null;
  }

  private getQueryParamFromHash(hash: string, param: string): string | null {
    const queryString = hash.split('?')[1];
    if (!queryString) {
      return null;
    }

    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
  }

  private saveTokenAsCookie(token: string) {
    const expiration = this.getTokenExpiryDate(token); // Extract expiry from token
    if (expiration) {
      document.cookie = `externalToken=${token}; expires=${expiration.toUTCString()}; path=/; Secure; SameSite=Strict`;
    }
  }

  private getTokenFromCookies(): string | null {
    const match = document.cookie.match(/(^| )externalToken=([^;]+)/);
    return match ? decodeURIComponent(match[2]) : null;
  }

  private getTokenExpiryDate(token: string): Date | null {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      if (payload && payload.exp) {
        const expiry = new Date(payload.exp * 1000);
        return expiry;
      }
    } catch (error) {
      console.error('Failed to decode token:', error);
    }
    return null;
  }

  private isTokenValid(token: string): boolean {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const now = Math.floor(Date.now() / 1000);
      const isExternalClientId = payload.clientId === 'external-task-client';
      return (payload.exp > now) && isExternalClientId;
    } catch (error) {
      console.error('Invalid token');
      return false;
    }
  }
}
