import { Component } from '@angular/core';

@Component({
  selector: 'oes-theme-proenergia',
  templateUrl: './proenergia.component.html',
  styleUrls: ['./proenergia.component.scss']
})
export class ProenergiaComponent {

  constructor() { }

}
