import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Milestone } from '@project/detail/milestones/milestone.model';
import { Project } from '@project/shared/project.model';
import { User } from '@user/user.model';
import { take } from 'rxjs';
import { environment } from '@environments/environment';

import { ExternalTaskService } from './external-task.service';
import { OesTaskType } from './task-type.enum';
import { OesTask } from './task.model';
import { EventService } from '@shared/event.service';

@Component({
  selector: 'oes-task',
  templateUrl: 'task.component.html',
  styleUrls: ['task.component.scss']
})

export class TaskComponent implements OnInit {
  formJson: any;
  imageError = false;
  logoUri: string;
  milestone: Milestone;
  project: Project;
  task: OesTask;
  user = new User({});

  private milestoneId: string;
  private taskId: string;

  private saveSubmitButton = {
    label: this._translateService.instant('buttons.submit-form'),
    key: 'submit',
    type: 'button',
    action: 'event',
    theme: 'primary',
    customClass: 'btn-save',
    input: true,
    tableView: true,
    mask: false,
    alwaysEnabled: false,
    showValidations: false,
    event: 'save',
    properties: {
      submitAfterSave: true   // Custom property
    },
    disableOnInvalid: true
  };

  constructor(private _activatedRoute: ActivatedRoute,
              private _translateService: TranslateService,
              private _eventService: EventService,
              private _externalTaskService: ExternalTaskService,
              private _router: Router) {
  }

  ngOnInit(): void {
    this.taskId = this._activatedRoute.snapshot.paramMap.get('id');
    this.getExternalTask();
  }

  private getExternalTask() {
    this._externalTaskService.getExternalTask(this.taskId)
      .pipe(take(1))
      .subscribe({
        next: (task: OesTask) => {
          this.task = task;
          this.logoUri = `${environment.organizationCdnUri}/${task?.organization?.logoImage?.id}`;
          switch (task.type) {
            case OesTaskType.MILESTONE:
              this.milestoneId = task.index;
              this.milestone = new Milestone(task?.indexObject);
              this.project = this.milestone?.project;
              this.getMilestoneForm();
              break;
            default:
              break;
          }
        },
        error: (error) => {
          if (error?.error?.message?.includes('Task completed')) {
            this._router.navigate(['completed'], {relativeTo: this._activatedRoute});
          }
          if (error?.error?.message?.includes('Task expired')) {
            this._eventService.error(this._translateService.instant('share-milestone.task-expired'));
          }
        }
      });
  }

  private getMilestoneForm() {
    this._externalTaskService.getExternalTaskMilestoneForm(this.taskId, this.milestoneId)
      .pipe(take(1))
      .subscribe(formJson => {
        formJson['components'].push(this.saveSubmitButton);
        this.formJson = formJson;
      });
  }

  openHomeNew() {
    const url = this._router.createUrlTree(['../../oes/home']).toString();
    window.open(url, '_blank');
  }
}
