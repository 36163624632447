<div class="header">
  <div class="d-flex flex-row w-100 justify-content-between">
    <div>
      <h4 class="title">{{ title }}</h4>
      <div *ngIf="showCount" class="subheader">
        <p>{{ completedMilestones }} {{ 'milestones.of' | translate }} {{ totalMilestones }} {{ 'milestones.milestones-completed' | translate }}</p>
      </div>
    </div>
    <div class="sub-action-container d-flex flex-row justify-content-end align-items-center flex-wrap">
      <button *ngIf="milestones.length && showExport" class="btn btn-fresh export-list-button" (click)="exportListClick()">
        <img style="height: 20px; width: 20px;" src="assets/icons/line-icons/shared/grey/download-cloud.svg" role="presentation" />
        {{ 'buttons.export-list' | translate }}
      </button>
      <ng-container *ngIf="addMilestone && !readOnly">
        <oes-button
          class="btn btn-primary btn-sm"
          (click)="handleOpenMilestoneConfigCreate()"
          [leadingIconSrc]="'assets/icons/line-icons/shared/white/plus.svg'"
        >{{ 'milestones.add-milestone' | translate }}</oes-button>
      </ng-container>
    </div>
  </div>
</div>

<div class="grid milestone-list-wrapper">
  <ng-container *ngIf="milestones.length; else milestonesEmpty">
    <ng-container *ngFor="let item of milestonesRange; index as i">
      <oes-milestone-row
        [index]="i"
        [milestoneId]="milestones[i].id"
        [milestoneType]="milestones[i].milestoneConfig.program ? milestoneTypes.PROGRAM : milestoneTypes.PROJECT"
        [project]="project"
        [projectOfftaker]="projectOfftaker"
        [projectInstaller]="projectInstaller"
        [statusDropdownOptions]="statusDropdownOptions"
      ></oes-milestone-row>
    </ng-container>
  </ng-container>
  <ng-template #milestonesEmpty>
    <div *ngIf="!emptyListContent; else emptyListContent" class="box-container d-flex flex-column justify-content-center align-items-center">
      <div class="icon-round-bg">
        <img style="height: 20px; width: 20px;" src="assets/icons/line-icons/shared/grey/upload-cloud.svg" role="presentation" />
      </div>
      <ng-container *ngIf="!isLoading; else loading">
        <p>{{ emptyMessage }}</p>
        <ng-container *ngIf="addMilestone && !readOnly">
          <a class="link-button" (click)="handleOpenMilestoneConfigCreate()">
            {{ 'milestones.add-milestone' | translate }}
          </a>
        </ng-container>
      </ng-container>
      <ng-template #loading>
        <div class="loading-ring loading-ring-small"><div></div><div></div><div></div><div></div></div>
      </ng-template>
    </div>
  </ng-template>
</div>
