import { NavItem } from '../nav-item.model';
import { CommercialInvestorDemoDiligenceNav } from '../commercial-investor-demo-diligence.constant';
import { CommercialInvestorDemoOriginateNav } from '../commercial-investor-demo-originate.constant';
import { CommercialInvestorDemoMonitorNav } from './commercial-investor-demo-monitor';

export class CommercialInvestorDemoNav {
  public static item: NavItem[] = [
    CommercialInvestorDemoOriginateNav.item,
    CommercialInvestorDemoDiligenceNav.item,
    CommercialInvestorDemoMonitorNav.item,
  ];
}
